<ng-container *ngIf="isLoggedIn; else unauthenticated">
  <app-side-nav-inner-toolbar title="{{appInfo.title}}">
    <router-outlet></router-outlet>
    <app-footer>
      Copyright © 2024-{{appInfo.currentYear}} {{appInfo.title}} Ltd.
      <br/>
      <img src="../../src/app/images/mountstreeticon.png" alt="MountStreet logo"/>
      All trademarks or registered trademarks are property of their respective owners.
    </app-footer>
  </app-side-nav-inner-toolbar>
</ng-container>

<ng-template #unauthenticated>
    <app-unauthenticated-content></app-unauthenticated-content>
</ng-template>
