<!--<header>
  
  <dx-toolbar class="header-toolbar">
    <dxi-item
      location="before"
      widget="dx-button"
      cssClass="menu-button"
      >
    </dxi-item>
    <h1>aac</h1>
    <dxi-item
      *ngIf="menuToggleEnabled"
      location="before"
      widget="dxButton"
      cssClass="menu-button"
      [options]="{
        icon: 'menu',
        stylingMode: 'text',
        onClick: toggleMenu
      }">
    </dxi-item>
    <dxi-item
      location="before"
      cssClass="header-title"
      *ngif="title"
      [text]="title">
    </dxi-item>
    
    <dxi-item
      location="after"
      locateInMenu="auto"
      menuItemTemplate="menuItem">
      <div *dxTemplate="let data of 'item'">
        <dx-button
          class="user-button authorization"
          width="210px"
          height="100%"
          stylingMode="text">
          <div *dxTemplate="let data of 'content'">
            <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="context"></app-user-panel>
          </div>
        </dx-button>
      </div>
    </dxi-item>
    <div *dxTemplate="let data of 'menuItem'">
      <app-user-panel [user]="user" [menuItems]="userMenuItems" menuMode="list"></app-user-panel>
    </div>
  <div><h1>ddd</h1></div>
    <!--<div *ngIf="isAuthenticated" style="cursor: pointer !important;" class="nav-item">
    <div>
      <a  (click)="showLogoutDialog();">
     <i class="material-icons">person</i> 
      <span style="color: black;">{{name}}</span>
    </a>
  </div>


  </dx-toolbar>
</header>-->
<header>
 
  <dx-toolbar class="header-toolbar">
    <dxi-item
    location = "after"
    cssClass="header-title"
    *ngIf="locale"
    [text]="locale">
  </dxi-item>
    
        <dxi-item
            location="after"
            widget="dxButton"
            hint="Log out"
            [options]="{
                icon: 'runner',
                onClick: showLogoutDialog
            }">
        </dxi-item>
    
  </dx-toolbar>
</header>
