import { startOfDay, endOfDay, startOfYear, endOfYear, 
    startOfWeek, endOfWeek, startOfMonth, endOfMonth, startOfQuarter, endOfQuarter } from 'date-fns'


export type DatePanelItem = { text: string, startDate: Date, endDate: Date, key: number };

export type Dates = { startDate: Date, endDate: Date};

export const datePanelItems: Array<DatePanelItem> = [
  {
      text: 'Today',
      startDate: getDayStart(),
      endDate: getDayEnd(),
      key: 1,
    }, 
  {
    text: 'Week',
    startDate: getWeekStartDate(),
    endDate: getWeekEndDate(),
    key: 2,
  }, {
    text: 'Month',
    startDate: getMonthStartDate(),
    endDate: getMonthEndDate(),
    key: 3,
  }, {
    text: 'Quarter',
    startDate: getQuarterStartDate(),
    endDate: getQuarterEndDate(),
    key: 4,
  }, {
    text: 'Year',
    startDate: getYearStart(),
    endDate: getYearEnd(),
    key: 5,
  }
];



export const docVaultAuthenticationKey : string = "docVaultAuthenticationKey";

  export function getYearEnd()
  {
    return endOfYear(new Date());
  }

  export function getYearStart()
  {
    return startOfYear(new Date());
  }

  export function getDayStart()
  {
     return startOfDay(new Date());
  }

  export function getDayEnd()
  {
     return endOfDay(new Date());
  }

  export function getWeekStartDate()
  {
    return startOfWeek(new Date(), {weekStartsOn: 1});
  }

  export function getWeekEndDate()
  {
    return endOfWeek(new Date(), {weekStartsOn: 1});
  }

  export function getMonthStartDate()
  {
    return startOfMonth(new Date());
  }

  export function getMonthEndDate()
  {
    return endOfMonth(new Date());
  }

  export function getQuarterStartDate()
  {
    return startOfQuarter(new Date());
  }

  export function getQuarterEndDate()
  {
    return endOfQuarter(new Date());
  }

 

