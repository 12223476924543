import { Component, Inject } from "@angular/core";
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

export interface DialogData {
  logoutMonitorValue: boolean;
}

@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.css']
})
export class LogoutDialogComponent {

    constructor(
      public dialogRef: MatDialogRef<LogoutDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
      this.data.logoutMonitorValue = true;
    }
  
    onNoClick(): void {
      this.dialogRef.close();
    }
  }


