import {gql} from 'apollo-angular';
import { Injectable, EventEmitter } from "@angular/core";
import { UserManager, User } from "oidc-client";
import {
  BehaviorSubject,
  combineLatest,
  Observable,
  ReplaySubject,
  Subject,
} from "rxjs";
import { environment } from '../../../../../environments/environment';
import { map } from "rxjs/operators";
import { LoggingService } from '../../../../framework/logging/logging.service';
import { SeverityLevel } from '../../../../framework/logging/severity-level.model';
import { AuthContext, IAuthContext } from "../models/security/auth-context";
import { IAuthContextResult } from "../models/security/gql";
import { AppInjector } from '../../../../app-injector.service';

import { DataGqlServiceLite } from '../../../services/data-gql.service.lite';
import { jwtDecode } from "jwt-decode";
import { Router } from "@angular/router";
import { SECURITY_EVENTS } from '../../../../framework/logging/types';
import { JwtHelperService } from '@auth0/angular-jwt';
// import { UserManagementService } from '../../user-management.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private _userManager: UserManager;
  private _user: User;
  private _loginChangedSubject = new Subject<boolean>();



  //sb auth - reimplement this
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  //sb auth - reimplement this
  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  //sb auth - reimplement this
  private isProfileLoadedSubject$ = new BehaviorSubject<boolean>(false);
  public isProfileLoaded$ = this.isProfileLoadedSubject$.asObservable();

  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
  ]).pipe(map((values) => values.every((b) => b)));

  loginChanged = this._loginChangedSubject.asObservable();

  authContext: AuthContext;
 

  public authContextUpdated: EventEmitter<IAuthContext> =
    new EventEmitter<IAuthContext>();

  constructor(
    private loggingService: LoggingService,
    protected router: Router,
    private jwtHelper: JwtHelperService
    // private userManagementService: UserManagementService
  ) {
    const stsSettings = {
      authority: environment.identityServerIssuer,
      client_id: environment.identityServerClientId,
      redirect_uri: `${window.location.origin}/signin-callback`,
      scope: 'openid profile web_api offline_access',
      response_type: 'code',
      post_logout_redirect_uri: `${window.location.origin}/signout-callback`,
      automaticSilentRenew: false,
      silent_redirect_uri: `${window.location.origin}/silent-refresh.html`,
    };

    this._userManager = new UserManager(stsSettings);
    console.log('userManager Tasks: ', this._userManager);
    this._userManager.events.addAccessTokenExpiring((x) => {
      loggingService.logError(
        'Access token expiring event',
        SeverityLevel.Information
      );
      this.renewToken().then((u) => {
        loggingService.logError(
          'Access token expiring event renew success',
          SeverityLevel.Information
        );
      });
    });

    this._userManager.events.addAccessTokenExpired((_) => {
      this._loginChangedSubject.next(false);
      loggingService.logError(
        'Access token expired',
        SeverityLevel.Information
      );
    });

    this._userManager.events.addUserLoaded((user) => {
      if (this._user !== user) {
        this._user = user;
        this.loadSecurityContext();
        this._loginChangedSubject.next(!!user && !user.expired);
        //sb auth - bridge
        this.setIsLoadingSubject(!!user && !user.expired);
      }
    });
  }

  login() {
    console.log('usermanager tasks: ', this._userManager);
    localStorage.setItem("isLoggingIn", "true")
    return this._userManager.signinRedirect();
  }

  isLoggedIn(): Promise<boolean> {
    return this._userManager.getUser().then((user) => {
      console.log('user: ', user);
      const userCurrent = !!user && !user.expired;

      if (this._user !== user) {
        this._loginChangedSubject.next(userCurrent);
      }

      if (userCurrent && !this.authContext) {
        this.loadSecurityContext();
      }

      this._user = user;
      //sb auth - bridge
      this.setIsLoadingSubject(userCurrent);
      return userCurrent;
    });
  }
  
  public isLoggingIn() : boolean
  {
    return localStorage.getItem("isLoggingIn") == "true" ? true : false;
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('tasksApiToken');
    const user = localStorage.getItem('loggedInUser');
    // Check whether the token is expired and return
    // true or false
    const valid =
      token !== null && !this.jwtHelper.isTokenExpired(token) && user !== null;
    return valid;
  }

  completeLogin() {
    return this._userManager.signinRedirectCallback().then((user) => {
      this._user = user;
      this._loginChangedSubject.next(!!user && !user.expired);

      //sb auth - bridge
      this.setIsLoadingSubject(true);
      this.loggingService.logEvent( SECURITY_EVENTS.LOGGED_IN),
        { userId: this._user.profile.sub };
      this.loadSecurityContext();
      localStorage.setItem("isLoggingIn", "false")
      return user;
    });
  }


  logout() {
    //clear start of user manager
    this.loggingService.logEvent(SECURITY_EVENTS.LOGGED_OUT);
      //{ userId: this._user.profile.sub };
    console.log(this._userManager);
    localStorage.clear();
    this._userManager.signoutRedirect();
  }

  completeLogout() {
    this._user = null;
    this.authContext = null;
    this._loginChangedSubject.next(false);
    return this._userManager.signoutRedirectCallback();
  }

  async loadSecurityContext() {
    var accessToken = await this.getAccessToken();

      if (accessToken) {

        var decodedToken = jwtDecode(accessToken);
        //var givenName = decodedToken['given_name'];
        var role =
          decodedToken[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
          ];
      }
      else {
        return null;
      }
      if (role) {
        this.authContext = new AuthContext();
        try {
          /*this.userManagementService.getAuthContext(accessToken).subscribe((result) => {
            this.authContext.claims = result.claims;
            this.authContext.userProfile = result.userProfile;
            this.authContextUpdated.emit(this.authContext);
            this.isProfileLoadedSubject$.next(true);
          });*/
        } catch (error) {
          this.loggingService.logError(error, SeverityLevel.Error);
          return null;
        }
      } else {
        this.loggingService.logError(
          SECURITY_EVENTS.INVALID_TOKEN,
          SeverityLevel.Error
        );
        this.router.navigate(['page-access-denied']);
        setTimeout(() => {
          this.logout();
        }, 5000);
      }
  }

  async loadSecurityContextOld() {
    let injector = AppInjector.getInstance().getInjector();
    let apollo = injector.get<DataGqlServiceLite>(DataGqlServiceLite);

    let finalresult = new Promise<IAuthContextResult>((resolve, reject) => {
      //reject(new Error("an error took place"));
    });

    const query = gql`
      query {
        authContext {
          claims {
            type
            value
          }

          userProfile {
            email
            firstName
            lastName
            id
            permittedDealIds
            permittedMandateIds
            userPermissions
          }
        }
      }
    `;

    let promise = await this.getAccessToken().then(
      async (accessToken) => {
        if (accessToken) {
          var decodedToken = jwtDecode(accessToken);
          //var givenName = decodedToken['given_name'];
          var role =
            decodedToken[
              'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];

          if (role) {
            try {
              let x = await apollo
                .query<IAuthContextResult>(query, null)
                .then((result) => {
                  this.authContext = new AuthContext();
                  this.authContext.claims = result.authContext.claims;
                  this.authContext.userProfile = result.authContext.userProfile;
                  this.authContextUpdated.emit(this.authContext);
                  this.isProfileLoadedSubject$.next(true);

                  finalresult = apollo.query<IAuthContextResult>(query, null);


                  return finalresult;
                });
            } catch (error) {
              this.loggingService.logError(error, SeverityLevel.Error);
            }
          } else {
            this.loggingService.logError(
              SECURITY_EVENTS.INVALID_TOKEN,
              SeverityLevel.Error
            );
            this.router.navigate(['page-access-denied']);
            setTimeout(() => {
              this.logout();
            }, 5000);
          }
        }
      },
      (err) => {
        return true;
      }
    );

    return finalresult;
  }

  async getAccessToken() {
    return await this._userManager.getUser().then((user) => {
      console.log('user in getAccessToken: ', user);
      if (!!user && !user.expired) {
        return user.access_token;
      } else {
        return null;
      }
    });
  }

  renewToken() {
    return this._userManager
      .signinSilent()
      .then((u) => {
        this._user = u;
      })
      .catch((error) => {
        this.loggingService.logException(error);
      });
  }

  setIsLoadingSubject(loaded: boolean) {
    this.isDoneLoadingSubject$.next(loaded);
    this.isAuthenticatedSubject$.next(loaded);
  }
}
