import { Component, OnInit } from '@angular/core';
import { TaskDataService } from '../../shared/services/task-data.service';
import { GeneratedTasksModel, IGeneratedTasksModel, IPreProcessedGeneratedTasksModel, ITaskDataPut, PreProcessedGeneratedTasksModel } from '../../modules/tasks/model/types';
import { LoggingService } from '../../framework/logging/logging.service';
import { StaticDataService } from '../../shared/services/static-data.service';
import { ProfileDataService } from '../../shared/services/profile-data.service';
import validationEngine from 'devextreme/ui/validation_engine';
import { DxValidationGroupTypes } from 'devextreme-angular/ui/validation-group';
import { DxValidationSummaryTypes } from 'devextreme-angular/ui/validation-summary';
import { mapGeneratedTask } from '../../shared/controllers/generatedtaskscontroller';
import { SeverityLevel } from '../../framework/logging/severity-level.model';

@Component({
  selector: 'app-generate-tasks',
  templateUrl: './generate-tasks.component.html',
  styleUrls: ['./generate-tasks.component.css']
})
export class GenerateTasksComponent implements OnInit {

  isLoading: boolean;
  referenceTypes: any[];
  templateYesNo: Array<{id: boolean, name: string}>;
  generatedTasksData: IPreProcessedGeneratedTasksModel = new PreProcessedGeneratedTasksModel();
  rollConventions: any[];
  frequencies: any[];
  tenors: any[];
  businessDayCalendars: any[];
  allUsers: any[];
  approverGroups: any[];
  teams: any[];
  taskTemplates: any[];
  allowEditing: boolean = false;
  allowAdding: boolean = false;
  editAction: any = "click"
  isGenerateTaskButtonEnabled: boolean = this.generatedTasksData.tasks.length > 0;

  onTemplateChanged(e: any)
  {
      if(e.value)
      {
        this.generatedTasksData.useTemplate = true;

        this.taskDataService.getDefaultTasksForTemplate(this.generatedTasksData.taskTemplateId).subscribe((data) =>{
          this.generatedTasksData.tasks = data[1].tasks;
        },
      (error) => { this.loggingService.logError(error, SeverityLevel.Error)});
      }
  }
  onValidateTemplate(e: any)
  {
    if(e.value == true && this.generatedTasksData.taskTemplateId )
      {
        this.allowEditing = true;
        this.allowAdding = false;
        return true;
      }
    else if (e.value == false && this.generatedTasksData.taskTemplateId)
      {
        this.generatedTasksData.taskTemplateId = null;
        this.generatedTasksData.tasks = [];
        this.allowEditing = true;
        this.allowAdding = true;
        return true;
      }
    else if (e.value == false && !this.generatedTasksData.taskTemplateId)
      {
        this.allowEditing = true;
        this.allowAdding = true;
        return true;
      }

    return false;

  
  }

  onValidateCycles(e: any)
  {
    if(e.value == 0)
    {
      e.value = null;
    }

    if((e.value != null && this.generatedTasksData.untilDate == null) ||
      (e.value == null && this.generatedTasksData.untilDate != null))
      {
        return true;
      }

    return false;
  }

  generateTasks(params) {
     let validationResult = validationEngine.validateGroup("formGroup");

     if(validationResult.isValid)
     {
        let x = mapGeneratedTask(this.generatedTasksData);

        this.taskDataService.getGeneratedTasks(x).subscribe((data) => {
          let y = data;
          this.allowAdding = false;
          this.allowEditing = true;

        },(error) => { this.loggingService.logError(error, SeverityLevel.Error)});
     }
        
  }

  constructor(private taskDataService: TaskDataService, private staticDataService: StaticDataService, private loggingService: LoggingService,
    private profileDataService: ProfileDataService) {
      this.onValidateCycles = this.onValidateCycles.bind(this);
      this.onValidateTemplate = this.onValidateTemplate.bind(this);
      this.onTemplateChanged = this.onTemplateChanged.bind(this);
    }

  ngOnInit() {
    
    this.templateYesNo = [{id:true, name: "Yes"}, {id:false, name:"No"}];
    this.taskDataService.getStaticData("referenceType").subscribe((rd: any) =>
      { 
        this.referenceTypes  = rd[1];
      },
     (error) => { this.loggingService.logError(error); });

     this.taskDataService.getTaskTemplates(false).subscribe((rd: any) =>
      { 
        this.taskTemplates  = rd[1];
      },
      (error) => { this.loggingService.logError(error); });

     this.staticDataService.getStaticData("tenor").subscribe((rd: any) =>
      { 
        this.tenors  = rd[1];
      },
     (error) => { this.loggingService.logError(error); });

     this.staticDataService.getStaticData("dateRollConvention").subscribe((rd: any) =>
      { 
        this.rollConventions  = rd[1];
      },
     (error) => { this.loggingService.logError(error); });

     this.staticDataService.getActiveBusinessDayCalendars().subscribe((rd: any) =>
      { 
        this.businessDayCalendars  = rd[1].results;
      },
     (error) => { this.loggingService.logError(error); });

     this.profileDataService.getAllUsers(false).subscribe((rd: any) =>
      { 
        this.allUsers  = rd[1];
      },
     (error) => { this.loggingService.logError(error); });

     this.profileDataService.getTeams().subscribe((rd: any) =>
      { 
        this.teams  = rd[1];
      },
     (error) => { this.loggingService.logError(error); });

     this.profileDataService.getApproverGroups().subscribe((rd: any) =>
      { 
        this.approverGroups  = rd[1];
      },
     (error) => { this.loggingService.logError(error); });

     this.frequencies = this.staticDataService.getTaskFrequencies();

  }

}
