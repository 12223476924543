import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoanDataService {

  baseUrl: string = environment.loanServiceEndPoint;
  controller: string = "get";

constructor(private  api: ApiService) {}

  getFacilities(pageStart: number, pageSize: number) : Observable<any>
  {
    return this.api.post(`${this.baseUrl}/Facility?IsActive=true&PageNumber=${pageStart}&PageSize=${pageSize}`, '[{ "columnName":"name", "byDescending": "false" }]');
  }
}
