import { Component, OnInit, NgModule } from '@angular/core';
import {  Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MatCard, MatCardHeader } from '@angular/material/card';
import { DxButtonModule } from 'devextreme-angular';




@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})



export class LoginComponent implements OnInit {

  baseUrl: string;
  loading: Boolean;

  constructor(
    private router: Router,
    private authService: AuthService
  )
  {}

  ngOnInit() {
    if(this.authService.isLoggedIn) {
      //this.router.navigate(['tasks']);
    }
    this.baseUrl = window.location.origin;
   
  }



  onSubmit(): void {
   
      this.doLogin();
    
  }



   doLogin(){
    this.loading = true;

     this.authService.login()

  }
  
}

@NgModule({
  imports: [
    MatCard,
    MatCardHeader,
    DxButtonModule
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent]
})
export class LoginModule { }
