import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';
import { Injectable } from '@angular/core';
import { SeverityLevel } from './severity-level.model';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../shared/services';
import { AppInjector } from '../../app-injector.service';
import { TRACKED_EVENT_TYPES } from './types';
import { SystemNotificationService } from '../../shared/services/system-notification.service';
import { MessageType } from '../system-notification/types';

// API Documentation
// https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md

//https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
@Injectable({ providedIn: 'root' })
export class LoggingService {

    appInsights: ApplicationInsights;

    constructor(private notificationService: SystemNotificationService) {
        this.appInsights = new ApplicationInsights({
          config: {
            instrumentationKey: environment.applicationInsightsInstrumentationKey,
            enableAutoRouteTracking: true // option to log all route changes
          }
        });
        this.appInsights.loadAppInsights();
      }

    logPageView(name?: string, url?: string) { // option to call manually
        if (environment && environment.logToAppInsights) {
            this.appInsights.trackPageView({
            name: name,
            uri: url,
            iKey: this.getUserId()
            });
        }
    }

    // Log non-exception type errors, e.g. invalid API request
    logError(error: any, severityLevel?: SeverityLevel) {
        if (environment && environment.logToConsole) {
            this.sendToConsole(error, severityLevel);
        }
      
        if (environment && environment.logToAppInsights) {
            this.appInsights.trackException(
                                                {   exception: new Error(error), 
                                                    severityLevel: severityLevel 
                                                },
                                                this.addUserIdToCustomProperties(null)
                                                
                                            );

            this.notificationService.raiseNotification({message: error, messageType: MessageType.Error});
        }
    }

    logEvent(name: TRACKED_EVENT_TYPES[keyof TRACKED_EVENT_TYPES], properties?: { [key: string]: any }) {
        if (environment && environment.logToAppInsights) {
            this.appInsights.trackEvent(
                                            { name: name }, 
                                            this.addUserIdToCustomProperties(properties)
                                        );
        }
    }

    logMetric(name: string, average: number, properties?: { [key: string]: any }) {
        if (environment && environment.logToAppInsights) {
            this.appInsights.trackMetric({ name: name, average: average }, this.addUserIdToCustomProperties(properties));
        }
    }

    logException(exception: Error, severityLevel?: number) {
        
        if (environment && environment.logToConsole) {
          this.sendToConsole(exception, severityLevel);
        }

        if (environment && environment.logToAppInsights) {
            this.appInsights.trackException({ exception: exception, severityLevel: severityLevel }, this.addUserIdToCustomProperties(null));
        }

        this.notificationService.raiseNotification({message: exception.message,messageType: MessageType.Error});
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        if (environment && environment.logToAppInsights) {
            this.appInsights.trackTrace({ message: message}, this.addUserIdToCustomProperties(properties));
        }
    }

    private sendToConsole(error: any, severityLevel: SeverityLevel = SeverityLevel.Error) {

        let date: Date = new Date();  
        // console.log("Date = " + date); //Date = Tue Feb 05 2019 12:05:22 GMT+0530 (IST)  

        switch (severityLevel) {
            case SeverityLevel.Critical:
            case SeverityLevel.Error:
                (<any>console).group('C|P Error: ' + date);
                console.error(error);
                if (error.message) {
                    console.error(error.message);
                }
                if (error.stack) {
                    console.error(error.stack);
                }
                (<any>console).groupEnd();
                break;
            case SeverityLevel.Warning:
                (<any>console).group('C|P Warning: ' + date);
                console.warn(error);
                (<any>console).groupEnd();
                break;
            case SeverityLevel.Information:
                (<any>console).group('C|P Information: ' + date);
                console.log(error);
                (<any>console).groupEnd();
                break;
        }
    }

    private addUserIdToCustomProperties(customProperties: any) : ICustomProperties {

        if (customProperties) {
            customProperties.userId = this.getUserId();
        }
        else {
            // customProperties =  {
            //     prop1: 'string',
            //     prop2: 123.45,
            //     prop3: { nested: 'objects are okay too' }   
            //  } 
            customProperties = {"userId" : this.getUserId()};
        }

        return customProperties
    }

    private getUserId () : string {

        const injector = AppInjector.getInstance().getInjector();
        let authService = injector.get(AuthService);

        let result = 'UNSET';

        if (!authService.isLoggedIn) {
            result = "NOT_LOGGED_IN"
        } else if (authService.authContext && authService.authContext.userProfile) {           
            result = authService.authContext.userProfile.id
        }

        return result;
    }
}
