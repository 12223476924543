
  <dx-drawer 

    [openedStateMode]="openedStateMode"
    [position]="selectedPosition"
    [revealMode]="selectedRevealMode"
    [closeOnOutsideClick]="false"
    [(opened)]="isDrawerOpen"
    [shading]="false"
    [minSize]= "0"
    [maxSize]="800"
    class="drawer"
    template="template"
    >

    
    <div *dxTemplate="let data of 'template'">
      <div style="width: 800px">
        <app-task-detail
        (closePanelEmitter)="closePanel()"
        [selectedTask]="selectedTask">
      </app-task-detail>
      </div>
    </div>

    <div class="container">
      
      <!--<data-grid  id="dataGrid" #dataGrid [rowData]="dataSource" [columnData]="taskColumnData" [childColumnData]="subTaskColumnData" [refreshMode]="refreshMode" [addNewText]="addNewText"
      [allowEditing]="false" (editorPreparing)="onEditorPreparing($event)" 
      (onRowClickEmitter)="onRowClick($event)" storageKey="Tasks_Grid" (loadNextDataEmitter)="loadNextData($event)" (loadPreviousDataEmitter)="loadPreviousData($event)"
      (onCellPreparedEventEmitter)="onCellPrepared($event)" (onContextMenuPreparingEmitter)="onContextMenuPreparing($event)" 
      (onAddNewEmitter) = "onAddNew($event)" [addNewOptions] = "addNewOptions" [gridFilterOptions] = "gridFilterOptions"
      (onFilterEmitter) = "onFilter($event)" [hasMasterDetail]="true" childPropertyKey="subTasks" 
      (onDetailCellPreparedEventEmitter)="onCellPrepared($event)" (onDetailContextMenuPreparingEmitter)="onContextMenuPreparing($event)" 
      (onDetailRowClickEmitter)="onDetailRowClick($event)" 
      ></data-grid>-->

        <dx-tree-list 
            id="treeListView"
            #dxTreeList
            [dataSource]="dataSource"
            keyExpr="id"
            parentIdExpr="parentId"
            (onRowClick)="onRowClick($event)"
            (onContextMenuPreparing)="onContextMenuPreparing($event)"
            (onCellPrepared)="onCellPrepared($event)"
            [allowColumnResizing]="true"
            columnResizingMode="nextColumn"
            [rowAlternationEnabled]="true">
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-selection mode="multiple"></dxo-selection>
            <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
            <dxo-scrolling mode="standard"></dxo-scrolling>
            <dxo-paging [enabled]="true" [pageSize]="10"> </dxo-paging>
            <dxo-pager
              [showPageSizeSelector]="true"
              [allowedPageSizes]="[10, 25, 50]"
              [showInfo]="true"
            >
            </dxo-pager>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-filter-panel [visible]="true"> </dxo-filter-panel>
            <dxo-column-fixing [enabled]="true"></dxo-column-fixing>
            <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
            <dxo-selection mode="multiple" [recursive]="true"></dxo-selection>
             <dxo-state-storing
              [enabled]="true"
              type="localStorage"
              [storageKey]="storageKey"
            ></dxo-state-storing>
            <dxo-toolbar>

              <dxi-item name="groupPanel">
                </dxi-item>
                <dxi-item location="after">
                  <dx-drop-down-button icon="filter" 
                      [items]="gridFilterOptions"
                      keyExpr="id"
                      displayExpr="text"
                      (onItemClick)= "onFilter($event)"
                      [dropDownOptions]="filterDropDownOptions"
                      hint="filter">
                        
                  </dx-drop-down-button>
                  
                  </dxi-item>
                  <dxi-item 
                    name="columnChooserButton"
                    locateInMenu="auto"
                    location="after"
                    hint="column chooser">
                  </dxi-item>
                  <dxi-item 
                  name="exportButton"
                  locateInMenu="auto"
                  location="after"
                  hint="export to Excel">
                </dxi-item>
                <dxi-item location="after">
                  <dx-button icon="filter" (onClick)="clearAllFilters()"
                  hint="clear all filters"> </dx-button>
                </dxi-item>
                <dxi-item location="after">
                  <dx-drop-down-button icon="card" 
                      [items]="userPreferenceActions"
                      keyExpr="id"
                      displayExpr="icon"
                      (onItemClick)= "userPreferencesSelectionChanged($event)"
                      [dropDownOptions]="dropDownOptions"
                      hint="user preferences">
                        
                  </dx-drop-down-button>
                  </dxi-item>
                  <dxi-item location="after">
                      <dx-button icon="refresh" (onClick)="refreshTreeView()"
                      hint="refresh datagrid"> </dx-button>
                  </dxi-item>
                  <dxi-item location="after">
                    <div *dxTemplate>
                      <dx-button icon="chevronprev" (onClick)="loadPreviousData($event)"
                      hint="previous dataset">  </dx-button>
                    </div>
                  </dxi-item>
                  <dxi-item location="after">
                    <div *dxTemplate>
                      <dx-button icon="chevronnext" (onClick)="loadNextData($event)"
                      hint="next dataset"> </dx-button>
                    </div>
                  </dxi-item>
                <dxi-item location="after">
                  <dx-drop-down-button icon="add" 
                      [items]="taskTemplates"
                      keyExpr="id"
                      displayExpr="name"
                      (onItemClick)= "onAddNew($event)"
                      [dropDownOptions]="addNewDropDownOptions"
                      hint="add new">
                        
                  </dx-drop-down-button>
                  
                  </dxi-item>
                  
            
            
            </dxo-toolbar>
          
            <ng-container *ngFor="let column of taskColumnData">
              <dxi-column [dataField]="column.field" [caption]="column.title" [dataType]="column.dataType" [allowEditing]="column.allowEditing" [calculateCellValue]="column.valueCalculator" [setCellValue]="column.setCellValue" [showEditorAlways]="column.showEditorAlways" > 
                <ng-container *ngIf="column.hasCustomFilter">
                  <dxo-header-filter [dataSource]="column.filterDataSource"></dxo-header-filter>
                </ng-container>
                <ng-container *ngIf="column.columnType === 'simpleLookup'">
                  <dxo-lookup [dataSource]="column.lookupConfig?.dataSource">
                  </dxo-lookup>
                </ng-container>
                <ng-container *ngIf="column.dataFormat && column.dataType === 'currency'">
                  <dxo-format type="fixedPoint" [precision]="column.precision">
                  </dxo-format>
                </ng-container>
              </dxi-column>
            </ng-container>

        </dx-tree-list>
        
   </div>
   </dx-drawer>
   <dx-load-panel
  container=".container"
  [position]="{ of: '.content' }"
  [visible]="isLoading"
  [showPane]="true"
  width="100"
  height="100"
  
></dx-load-panel>

<dx-popup
    height="900"
    width="750"
    title="New Task"
    position= "center"
    [showCloseButton] = "true"
    id="newTask"
    [(visible)]="addNewTaskVisible"
    contentTemplate="popupContent"
    >
    
    <div *dxTemplate="let data of 'popupContent'">
      <app-new-task
      [addNewTemplateType] ="addNewTemplateType"
      (onAddNewTaskEventEmitter)="onAddNewTask($event)"
      >
      </app-new-task>
    </div>
      
      
</dx-popup>

    
  

    
    
 

