import { Component, NgModule, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthService } from '../../services';
import { IUser } from '../../../modules/deal/model/types';
import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import {AppComponent} from '../../../app.component';
import { Observable } from "rxjs";
import { MatDialog } from '@angular/material/dialog';
import { LogoutDialogComponent } from '../framework-ui/navigation/logout/logout-dialog/logout-dialog.component';

import { Router } from '@angular/router';
@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @Output()
  menuToggle = new EventEmitter<boolean>();

  @Input()
  menuToggleEnabled = false;

  @Input()
  title!: string;

  locale!: string;

  isAuthenticated: boolean;

  user: IUser | null = { email: '', id:'', isActive: true, updatedBy: '', updatedTimestamp: '', name: ''};

  userMenuItems = [{
    text: 'Profile',
    icon: 'user',
    onClick: () => {
      this.router.navigate(['/profile']);
    }
  },
  {
    text: 'Logout',
    icon: 'runner',
    onClick: () => {
      this.authService.logout();
    }
  }];



  isAuthenticated$: Observable<boolean>


  constructor(private dialog: MatDialog, private authService: AuthService, private router: Router, private appComponent : AppComponent) 
  { 
    this.locale = appComponent.appLocale;
    this.isAuthenticated = true;//this.authService.isAuthenticated$;
    this.isAuthenticated$ = this.authService.isAuthenticated$;
    this.isAuthenticated = true;
  }

 showLogoutDialog = () => {
    const dialogRef = this.dialog.open(LogoutDialogComponent, { data: {} });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.authService.logout();
      }
    });
  }

  ngOnInit() {
    //..then((e) => this.user = e.data);
  }

  toggleMenu = () => {
    this.menuToggle.emit();
  }

  

  get name() {
    if (
      this.authService &&
      this.authService.authContext &&
      this.authService.authContext.userProfile
    ) {
      return this.authService.authContext.userProfile.firstName;
    }

    return "undefined";
  }

}

@NgModule({
  imports: [
    CommonModule,
    DxButtonModule,
    UserPanelModule,
    DxToolbarModule
  ],
  declarations: [ HeaderComponent ],
  exports: [ HeaderComponent ]
})
export class HeaderModule { }
