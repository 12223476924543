import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


@Component({
  selector: 'app-new-task',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.css']
})
export class NewTaskComponent implements OnInit {
 @Input() addNewTemplateType: string;

  @Output() addNewTaskEventEmitter: EventEmitter<any> = new EventEmitter();

  onAddNewTask(e: any)
  {
    this.addNewTaskEventEmitter.emit(e);
  }
 
  constructor() { }

  ngOnInit() {
    
  }

}
