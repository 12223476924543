<div><div>{{addNewTemplateType}}</div>
  <dx-scroll-view>
    <div [ngSwitch]="addNewTemplateType">
        <div *ngSwitchCase="'facility template 1'">
          <app-voucher (onAddNewTaskEventEmitter)="onAddNewTask($event)"></app-voucher>
        </div>
        <div *ngSwitchCase="'Payoff/Paydown'">
          <app-payoff-paydown (onAddNewTaskEventEmitter)="onAddNewTask($event)"></app-payoff-paydown>
        </div>
        <div *ngSwitchDefault>No task type available!</div>
      </div>
        
    </dx-scroll-view>
</div>