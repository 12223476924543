import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services';
import { LoggingService } from '../../../../framework/logging/logging.service';

@Component({
    selector: 'app-signout-callback',
    template: `
                    <div class="container-fluid">
                        <h2 class="logon-status-info">Logging Out...</h2>
                    </div>
               
            `
})

export class SignOutRedirectCallbackComponent implements OnInit {
    constructor(private _authService: AuthService,
                private _router: Router, private loggingService: LoggingService ) { }

    ngOnInit() {
        this._authService.completeLogout().then(_ => {
            this._router.navigate(['/']);
        })
     }
}