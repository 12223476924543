import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
    providedIn: 'root'
  })

   export class StaticDataService
    {
          constructor(private api: ApiService) { }
  
          private baseUrl: string = environment.staticDataServiceEndPoint;

         
          
  
          public getStaticData(staticDataType: string): Observable<any>
          {
              return this.api.get(`${this.baseUrl}/StaticData?type=${staticDataType}`)
          }

          public getActiveBusinessDayCalendars(): Observable<any>
          {
            return this.api.get(`${this.baseUrl}/BusinessCalendar?IsActive=true`);
          }

          public getTaskFrequencies(): any
          {
            return [
                { id: "0", text: "None" },
                { id: "1", text: "1" },
                { id: "2", text: "2" },
                { id: "3", text: "3" },
                { id: "4", text: "4" },
                { id: "5", text: "5" }
            ]
          }
    }