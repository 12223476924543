
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ITaskResponse } from './../../../modules/tasks/model/types';
import { TaskDataService } from '../../../shared/services/task-data.service';


@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.css']
})



export class TaskDetailComponent implements OnInit {

  @Input() selectedTask: ITaskResponse

  @Output() closePanelEmitter: EventEmitter<any> = new EventEmitter();

  taskStatuses: any[];

  getTaskStatuses()
    {
      
    }
  
  constructor(private taskDataService: TaskDataService) { }

  closePanel()
  {
    this.closePanelEmitter.emit();
  }

  ngOnInit() {

    
  }

} 
