import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TaskDataService,  } from '../../../../shared/services/task-data.service';
import { IServiceRequest, ITaskVoucherData, ServiceRequest, TaskVoucherData } from '../../../../modules/tasks/model/types';
import { LoanDataService } from '../../../../shared/services/loan-data.service';
import notify from 'devextreme/ui/notify';
import validationEngine from "devextreme/ui/validation_engine";
import { TreasuryDataService } from '../../../../shared/services/treasury-data.service';
import { Router } from '@angular/router';
import { getWeekStartDate, getWeekEndDate } from '../../../../types/resource';
import { LoggingService } from '../../../../framework/logging/logging.service';
import { SeverityLevel } from '../../../../framework/logging/severity-level.model';


@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {

  @Output() onSubmitTaskEventEmitter: EventEmitter<any> = new EventEmitter();

  taskVoucherTypes: any[];
  bankData: any[];
  voucherData:  ITaskVoucherData; 
  loanData: any[];
  billableToClientData: any[];
  endDate: Date;
  startDate: Date;
  isLoading: boolean = false;

  constructor(private taskDataService: TaskDataService, private loanDataService: LoanDataService,
                    private treasuryDataSevice: TreasuryDataService, private router: Router, private loggingService: LoggingService
  ) { 

    this.startDate = getWeekStartDate();
    this.endDate = getWeekEndDate();
    
  }

  

  onSubmit(e: Event)
  {
    let validationResult = validationEngine.validateGroup("formGroup");
    
    if (!validationResult.isValid)
    {
      e.preventDefault();
    }
    else
    {
        let request: IServiceRequest = new ServiceRequest(); 
        request.mandate = "Test";
        request.serviceRequestType = this.voucherData.voucherType;
        request.referenceName = this.voucherData.loanNumber;
        request.requestJsonData = JSON.stringify(this.voucherData);
        request.referenceType ='Deal';
        request.location = "GB-WLS";

        let response: any;

        this.isLoading = true;

        this.taskDataService.insertServiceRequest(request).subscribe((data: { data: any; }[]) => {
          response = data;
          this.isLoading = false;
          notify(response[0]);
          //this.router.navigate(['/tasks'],{ state: {startDate: this.startDate, endDate: this.endDate}});
          this.onSubmitTaskEventEmitter.emit(e);
        },
        (error) => {
          this.loggingService.logError(error, SeverityLevel.Error);
          notify("Failed to create request!");
          this.isLoading = false;
          this.onSubmitTaskEventEmitter.emit(e);
        }
        
        );

        e.preventDefault();

        
       

        
    }

  }

  ngOnInit() {

    this.isLoading = true;

    this.taskVoucherTypes = this.taskDataService.getTaskVoucherTypes();
  
    this.treasuryDataSevice.getBanks().subscribe((data: { data: any; }[]) => {
      this.bankData  = data;
    }
    ,
    (error) => { this.loggingService.logError(error, SeverityLevel.Error)}
  );

  // yukky for page size 
    this.loanDataService.getFacilities(1, 1000000).subscribe((data: any) =>
    {
        this.loanData = data[1];
        this.isLoading = false;
    }
    ,
    (error) => { this.loggingService.logError(error, SeverityLevel.Error);
      this.isLoading = false;
    }
  );


    this.voucherData = new TaskVoucherData();
    this.billableToClientData = [
     { id: 1, name: "Yes" },
     { id: 2, name: "No" }
    ]
  }

}
