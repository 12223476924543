import { Component, HostBinding } from '@angular/core';
import { ScreenService, AppInfoService} from './shared/services';
import { ProfileDataService } from './shared/services/profile-data.service';
import { AuthService } from './shared/services';
import { locale } from 'devextreme/localization'
import config from 'devextreme/core/config'; 
import { licenseKey } from './devextreme-license'; 
import { AuthGuardService } from './shared/components/framework/services/auth-guard.service';
import { LoggingService } from './framework/logging/logging.service';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  {
  @HostBinding('class') get getClass() {
    return Object.keys(this.screen.sizes).filter(cl => this.screen.sizes[cl]).join(' ');
  }

  appLocale: string
  isLoggedIn: Boolean;

  constructor(private authService: AuthService, private screen: ScreenService, public appInfo: AppInfoService, private loggingService: LoggingService) 
  { 
      locale(navigator.language);
      this.appLocale =  navigator.language
      config({licenseKey});
      
      //localStorage.clear();
      /*localStorage.setItem("userId", '608952ff-70ce-438f-810b-273744becb84');
      let userId = localStorage.getItem("userId");
      
      if(userId != null)
        profileDataService.getUserPreferences(userId).subscribe((rd: any) =>
        {
          localStorage.setItem("userPreferences", rd);
        }); */
      //locale('ja-JP');

      
  }

  

  ngOnInit() {

    if(this.authService.isLoggingIn())
    {
      this.isLoggedIn = true;
    }
    else{
      this.isLoggedIn = this.authService.isAuthenticated();
    }
    
   
  }
  

}


