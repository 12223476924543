import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import {Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TreasuryDataService {

  private bankController = 'Banks';
  private getMethod = 'Get';
  private baseUrl: string = environment.treasuryServiceEndPoint;
constructor(private api: ApiService) { }

getBanks() : Observable<any>
{
  return this.api.get(`${this.baseUrl}/${this.bankController}`)
}

}
