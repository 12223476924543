export const environment = {
    // remember to update assets/env.template.js
  
    production: true,
  
    envName: window["env"]["envName"],
  
    //appInsights
    applicationInsightsInstrumentationKey: window["env"]["applicationInsightsInstrumentationKey"],
  
    //logging
    logToConsole: false,
    logToAppInsights: true,
    traceEnabled: false,
  
    requireAuth: true,
  
    identityServerIssuer: window["env"]["identityServerIssuer"],
    identityServerClientId: window["env"]["identityServerClientId"],
    gQLEndpoint: window["env"]["gQLEndpoint"],
    docVaultServiceEndpoint: window["env"]["docVaultServiceEndpoint"],
    mandateConfigData: window["env"]["mandateConfigData"],
    taskManagerServiceEndPoint: window["env"]["taskManagerServiceEndPoint"],
    treasuryServiceEndPoint:  window["env"]["treasuryServiceEndPoint"],
    profileServiceEndPoint: window["env"]["profileServiceEndPoint"],
    loanServiceEndPoint: window["env"]["loanServiceEndPoint"],
    staticDataServiceEndPoint: window["env"]["staticDataServiceEndPoint"],
  };
  