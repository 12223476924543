import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { HttpClientModule } from '@angular/common/http';

import { CommonModule } from '@angular/common';
import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { DxHttpModule } from 'devextreme-angular/http';
import { SideNavOuterToolbarModule, SideNavInnerToolbarModule, SingleCardModule } from './layouts';
import { FooterModule, ResetPasswordFormModule, CreateAccountFormModule, ChangePasswordFormModule} from './shared/components';
import { AuthService, ScreenService, AppInfoService } from './shared/services';
import { UnauthenticatedContentModule } from './unauthenticated-content';
import { AppRoutingModule } from './app-routing.module';
import { DxFileUploaderModule, DxFileManagerModule, DxButtonModule, DxTooltipModule,
   DxValidatorModule, DxDataGridModule, DxLoadPanelModule } from 'devextreme-angular';
import { GraphQLModule } from './graphql.module';
import { AppInjector } from './app-injector.service';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';






@NgModule({
  declarations: [
    AppComponent,
  
  ],
  imports: [
    BrowserModule,
    DxHttpModule,
    HttpClientModule,
    SideNavOuterToolbarModule,
    SideNavInnerToolbarModule,
    SingleCardModule,
    FooterModule,
    ResetPasswordFormModule,
    CreateAccountFormModule,
    ChangePasswordFormModule,
    UnauthenticatedContentModule,
    AppRoutingModule,
    CommonModule,
    DxFileUploaderModule,
    DxButtonModule,
    DxFileManagerModule,
    DxTooltipModule,
    DxValidatorModule,
    DxDataGridModule,
    DxLoadPanelModule,
    GraphQLModule,
   
    
  ],
  providers: [
    AuthService,
    ScreenService,
    AppInfoService,
    provideAnimationsAsync(),
    
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

    constructor(private injector: Injector) {
      AppInjector.getInstance().setInjector(injector);
    }
 }
