import {Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';
import { LoggingService } from '../../framework/logging/logging.service';
import { GlobalEventsService } from './global-events.service';
import { environment } from '../../../environments/environment';


@Injectable({
    providedIn: 'root'
  })
export class DataGqlServiceLite {

    protected apiServer = environment ? environment.gQLEndpoint : null;

    constructor(
        protected loggingService: LoggingService,
        protected globalEventsService: GlobalEventsService,
        private apollo: Apollo
    ) { }

    public query<T>(query: any, variables: any) {

        const promise = new Promise<T>((resolve, reject) => {
            new Promise<void>(() => {
                this.globalEventsService.startLoadingData();
                this.apollo
                .query<T>({
                  query: query,
                  variables: variables,
                  fetchPolicy: "network-only",
                })
                .subscribe((response) => {
                  this.globalEventsService.completeLoadingData();
                  resolve(response.data);
                });
            }).catch((response: any) => {
                const error = this.logError({
                    url: query,
                    response: response
                });
                this.globalEventsService.completeLoadingData();
                reject(error);
            });
        });
        return promise;
    }

    private logError(error: { url: string, body?: any, response: any }) {

        this.loggingService.logError(error.response);

        try {
            if (error.response._body && JSON.parse(error.response._body).error) {
                return JSON.parse(error.response._body).error;
            }
        } catch (e) { }

        if (error.response.status) {
            return `${error.response.status} - ${error.response.statusText}`;
        }
        return 'Error calling API';
    }
}
