import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from 'rxjs';
import { ISystemNotification } from "../../framework/system-notification/types";

@Injectable({ providedIn: 'root' })
export class SystemNotificationService{

    private notificationParamState = new BehaviorSubject<ISystemNotification>(null);

    notificationParam: Observable<ISystemNotification>;

    constructor() {
        this.notificationParam= this.notificationParamState.asObservable()
    }

    raiseNotification(notification: ISystemNotification) {
        this.notificationParamState.next(notification)
    }
}