import { IBusinessCalendar, ITaskType } from "../../request/model/types";


export class TaskDataPut implements ITaskDataPut{
  task: ITaskPut;
  taskGroups: ITaskGroup[];
  taskUpdatedTimestamp: Date;
  action: string;
  id: string;
  assignedUser: string;
  dueDate: Date;
  createdOn: Date;
  createdBy: string;
  alertDate?: Date;
  snoozeDate?: Date;
  maxSnoozeDays?: number;
  dateCompleted?: Date;
  saskConfigurationId?: string;
  name: string;
  description: string;
  taskSlaDays?: number;
  taskSlaHours?: number;
  taskSlaMinutes?: number;
  approverId?: string;
  rejectorId?: string;
  completerId?: string;
  comment: string;
  rescheduleOnExecution: boolean;
  riskAllocationId: string;
  sameDayCutOffHours?: number;
  sameDayCutOffMinutes?: number;
  locations: string;
  taskType: ITaskType;
  taskData: string;
  requestReceivedDateTime?: Date;
  workflowId?: string;
  isExternal?: boolean;
  taskTags: ITaskTag[];
  
  constructor()
  {
    this.taskTags = new Array<TaskTag>;
  }
}



export interface IGeneratedTasksModel
{
   assignedUserName: string;
   approverGroup: string;
   secondApproverGroup: string;
   thirdApproverGroup: string;
   team: string;
   referenceTypeId: string;
   referenceType: string;
   referenceId: string;
   reference: string;
   referenceDate: Date;
   tenor: string;
   frequency?: number;
   numberOfCycles?: number;
   untilDate?: Date;
   dateRollConvention: any;
   location: string;
   tasks: any[]
   workflowId: string;
  referenceDateIsFirstDueDate: boolean;
}

export class GeneratedTasksModel implements IGeneratedTasksModel
{
  constructor()
  { this.tasks = [];}
  assignedUserName: string;
  approverGroup: string;
  secondApproverGroup: string;
  thirdApproverGroup: string;
  team: string;
  referenceTypeId: string;
  referenceType: string;
  referenceId: string;
  reference: string;
  referenceDate: Date;
  tenor: string;
  frequency?: number;
  numberOfCycles?: number;
  untilDate?: Date;
  dateRollConvention: any;
  location: string;
  tasks: any[];
  workflowId: string;
  referenceDateIsFirstDueDate: boolean;

}

export interface IPreProcessedGeneratedTasksModel extends IGeneratedTasksModel
{
  locationArray: Array<string>;
  taskTemplateId: string;
  useTemplate: boolean;
}
export class PreProcessedGeneratedTasksModel extends GeneratedTasksModel  implements IPreProcessedGeneratedTasksModel 
{
  locationArray: Array<string>;
  taskTemplateId: string;
  useTemplate: boolean;
}

export interface ITaskDataPut extends ITaskPut{
  task: ITaskPut;
  taskGroups: ITaskGroup[];
  taskUpdatedTimestamp: Date;
  action: string;
}
export interface ITaskPut extends ITaskPost{
  id: string;
}
export interface ITaskPost
{
   assignedUser: string; 
   dueDate: Date;
   createdOn: Date;
   createdBy: string;
   alertDate?: Date; 
   snoozeDate?: Date; 
   maxSnoozeDays?: number; 
   dateCompleted?: Date;
   saskConfigurationId?: string;
   name: string; 
   description: string;
   taskSlaDays?: number;
   taskSlaHours?: number;
   taskSlaMinutes?: number; 
   approverId?: string; 
   rejectorId?: string; 
   completerId?: string; 
   comment: string; 
   rescheduleOnExecution: boolean; 
   riskAllocationId: string; 
   sameDayCutOffHours?: number; 
   sameDayCutOffMinutes?: number;
   locations: string; 
   taskType: ITaskType; 
   taskData: string;
   requestReceivedDateTime?: Date; 
   workflowId?: string; 
   isExternal?: boolean; 
   taskTags: ITaskTag[]; 
}

export interface ITaskResponse{
  id: string;
  parentId: string;
  hasParent: boolean;
 batchId: string;
 status: string;
 sequenceNumber: number;
 taskId: string;
 dueDate: Date;
 alertDate?: Date; 
 dateCompleted?: Date; 
 referenceType: string;
 referenceName: string;
 mandate: string; 
// Guid? TaskConfigurationId 
 name: string; 
 description: string; 
 taskSLADays: number;
 taskSLAHours?: number;
 taskSLAMinutes?: number; 
 approverName: string; 
 rejectorName: string; 
 completerName: string;
 comment: string; 
 riskAllocation: string; 
 taskTags: ITaskTag[]; 
// int? SameDayCutOffHours 
// int? SameDayCutOffMinutes 
  calendars: IBusinessCalendar[]; 
  taskType: string;
  taskData: string; 
// DateTime? RequestReceivedDateTime 
 assignedUserName: string;
 team: string; 
 approverGroup: string; 
 secondApproverGroup: string; 
 thirdApproverGroup: string; 
 taskGroups: ITaskGroup[]; 
 lastComment: string;
 lastCommentName: string; 
 lastCommentDate: Date; 
 lastActionDate: Date; 
 isInBreach?: boolean; 
 isOverDue: boolean; 
 urls: IServiceRequestUrl[];
 createdOn: Date;
 createdBy: string; 
 isActive: boolean;




}

export interface ITaskGroup
{
GroupType: string;
GroupName: string;
ApproverLevel: number;
}

export interface IServiceRequestUrl
{
 ServiceRequestId: string;
 ServiceRequest: IServiceRequestUrl;
 DisplayText: string;
 Url: string;
}


export interface ITaskTag {
  key: string;
  value: string;
}
export class TaskTag implements ITaskTag
{
  key: string;
  value: string;
  
}



export interface ITasksSummary{
  statusName: string
  statusCount: number
}

export interface ITasksNavigation
{
  startDate : Date;
  endDate: Date;
  taskStatus: string;
  name: string;
  taskType: string;
  myTasks: boolean;
}

export class TasksNavigation implements ITasksNavigation
{
  startDate: Date;
  endDate: Date;
  taskStatus: string;
  name: string;
  taskType: string;
  myTasks: boolean;
  
}

export interface IValidationResult {
    isValid: boolean, 
    field: string, 
    value: string
  }
  
  export interface IGroupItem {
    column: string,
    summaryType: string
  }
  export interface ITransactionImportLog {
    fileName: string;
    message: string;
    transactionDate: string;
    transaction: any;
    exception: any;
    updatedTimestamp: string;
  }
  
  export interface IGridColumn {
    dataType: string;
    field?: string;
    title: string;
    textFormat?: string | Function;
    dataFormat?: object;
    precision?: number;
    valueCalculator?: any;
    columnType?: string;
    lookupConfig?: {
      dataSource: any;
      valueExpression?: any;
      displayExpression?: any;
    };
    setCellValue?: Function;
    allowEditing?: boolean;
    hasCustomFilter?: boolean;
    filterDataSource?: any;
    showEditorAlways?: boolean;
    icons?: string[];

  }
  
  export interface IGridSummary {
    name: string;
    calculateFunction?: Function;
    totalItems: ISummaryTotalItem[] | null;
    groupItems: IGroupItem[] | null;
  }
  
  export interface ISummaryTotalItem {
    type: string;
    name: string;
    summaryType: string;
    valueFormat?: string | null;
    displayFormat?: string | null;
    customizeText?: Function | null;
    column: string;
  }
  
  export interface IKeyValuePair {
    key: any,
    value: any
  }
  
  export interface ITab {
    id: number,
    text: string,
    icon: string,
    content: any
  }
  
  export interface ICellInfo {
    cellElement?: any,
    rowIndex?: number,
    columnIndex?: number
  }
  
  export interface ISelectedRange {
    startRowIndex?: number,
    endRowIndex?: number,
    startColumnIndex?: number,
    endColumnIndex?: number
  }

  export interface ITaskVoucherData
  {
    voucherType: string;
    feesPaidToMS: boolean;
    bank: string;
    voucherAmount: number;
    loanNumber: string;
    billableToClient: string;
    dueDate: Date;
  }

  export class TaskVoucherData implements ITaskVoucherData
  {
    voucherType: string;
    feesPaidToMS: boolean = false;
    bank: string;
    voucherAmount: number;
    loanNumber: string;
    billableToClient: string;
    dueDate: Date;
  }

  export interface IServiceRequest{
    mandate: string;
    referenceName: string;
    referenceType: string;
    location: string;
    serviceRequestType: string;
    requestJsonData: string;
    isUrgent: boolean;
    description: string;
    dueDate: Date;
  }

  export class ServiceRequest implements IServiceRequest
  {
    mandate: string;
    referenceName: string;
    referenceType: string;
    location: string;
    serviceRequestType: string;
    requestJsonData: string;
    isUrgent: boolean = false;
    description: string;
    dueDate: Date;
  }