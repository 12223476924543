import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs/internal/Observable';
import { UtilService } from './util.service';
import { formatDate } from 'date-fns';
import { IGeneratedTasksModel, IServiceRequest } from '../../modules/tasks/model/types';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })


  export class TaskDataService
  {
        constructor(private api: ApiService, private utilService: UtilService) { }

        private baseUrl: string = environment.taskManagerServiceEndPoint;
        private controller = 'Task';
        private getMethod = 'Get';
        private dateFormat: string = "yyyy/MM/dd";
        private statusFlags: string  = "&IncludeOverdueStatus=true&IncludeAlertStatus=true&IncludeBacklogStatus=true&IncludeHighRisk=true&IncludeMediumRisk=true&IncludeLowRisk=true&IncludeOtherRisk=true";

        

        public getStaticData(staticDataType: string)
        {
            return this.api.get(`${this.baseUrl}/StaticData?type=${staticDataType}`)
        }
        
        public getTaskTemplates(isWorkflow: boolean) : Observable<any>{
            return this.api.get(`${this.baseUrl}/TaskTemplates?isActive=true&isWorkflow=${isWorkflow}`)
        }

        public getDefaultTasksForTemplate(templateId: string) : Observable<any>
        {
            return this.api.get(`${this.baseUrl}/TaskTemplates/DefaultTasks?TemplateId=${templateId}`);
        }

        
        public assignTaskToMe(taskId: string): Observable<any>{
     
            return this.api.post(`${this.baseUrl}${this.controller}/AssignToMe`, `[ "${taskId}" ]`);
         }

        public getAll(pageStart: number,  pageSize: number): Observable<any> {
           // return this.api.get<ITaskResponse[]>(this.baseUrl + '/' +  this.controller + '/' + this.getMethod);
           return this.api.post(`${this.baseUrl}/${this.controller}/${this.getMethod}?IsActive=true&PageNumber=${pageStart}&PageSize=${pageSize}${this.statusFlags}`, '[{ "columnName":"dueDate", "byDescending": "true" }]');
 
        }

        public getAllForUser(pageStart: number,  pageSize: number, userId: string)
        {
            return this.api.post(`${this.baseUrl}/${this.controller}/${this.getMethod}?IsActive=true&PageNumber=${pageStart}&PageSize=${pageSize}${this.statusFlags}&UserId=${userId}`, '[{ "columnName":"dueDate", "byDescending": "true" }]');
        }

        public getAllForStatusInDateRange(pageStart: number,  pageSize: number, startDate: Date, endDate: Date, status: string)
        {
            //let filter: string  = `Statuses=${status}&DueDateRangeFilter.From=${formatDate(startDate, this.dateFormat)}&DueDateRangeFilter.To=${formatDate(endDate, this.dateFormat)}`;
            let filter: string  = `DueDateRangeFilter.From=${formatDate(startDate, this.dateFormat)}&DueDateRangeFilter.To=${formatDate(endDate, this.dateFormat)}`;
            return this.api.post(`${this.baseUrl}/${this.controller}/${this.getMethod}?IsActive=true&PageNumber=${pageStart}&PageSize=${pageSize}&${filter}${this.statusFlags}`, '[{ "columnName":"dueDate", "byDescending": "true" }]');
        }

        public getAllInDateRange(pageStart: number,  pageSize: number, startDate: Date, endDate: Date)
        {
            let filter: string  = `DueDateRangeFilter.From=${formatDate(startDate, this.dateFormat)}&DueDateRangeFilter.To=${formatDate(endDate, this.dateFormat)}`;
            return this.api.post(`${this.baseUrl}/${this.controller}/${this.getMethod}?IsActive=true&PageNumber=${pageStart}&PageSize=${pageSize}&${filter}${this.statusFlags}`, '[{ "columnName":"dueDate", "byDescending": "true" }]');
        }

       public insertServiceRequest(request: IServiceRequest)
       {
          return this.api.post(`${this.baseUrl}/ServiceRequest/InsertServiceRequest`, request);
       }

       public getGeneratedTasks(model: IGeneratedTasksModel) : Observable<any>
       {
            return this.api.post(`${this.baseUrl}/GeneratedTasks/GetTasks`, model);
       }

     

        public saveTask(task: any)
        {
            //let x = this.utilService.convertTasks([task,]);

            //this.api.put(`${this.baseUrl}${this.controller}`, [task,]);
        }

        getTaskVoucherTypes()
        {
            return [
                {id: 1, name: "Borrower Disbursement"},
                {id: 2, name: "Intracompany"},
                {id: 3, name: "Multi-Credit"},
                {id: 4, name: "Third Party"},
                {id: 5, name: "Third Party-Tax"},
                {id: 6, name: "Third Party-Insurance"},
                {id: 7, name: "Waterfall"},
            ];
        }

        
}