
<dx-scroll-view>
  <form (submit)="onSubmit($event)">
      <dx-form id="form" 
      [formData]="payoffpaydowndata"
      [showValidationSummary]="true"
      validationGroup="formGroup"
      >
        <dxi-item itemType="group" cssClass="form-group form-group-content" [colCount]="2">
          <dxi-item
              labelMode="static"
              dataField="RequestType"
              editorType="dxSelectBox"
              [editorOptions]="{ items: requesttypes, valueExpr: 'id', displayExpr: 'name', width: '70%', required: 'true'  }"
            >
            <dxo-label text="Request Type"> </dxo-label>
            <dxi-validation-rule
            type="required"
            message="Request Type is required">
          </dxi-validation-rule>
          </dxi-item>
            <dxi-item
              dataField="TransactionType"
              editorType="dxSelectBox"
              [editorOptions]="{ items: transactiontypes, valueExpr: 'id', displayExpr: 'name', width: '70%', required: 'true' }"    
            >
            <dxo-label text="Transaction Type"> </dxo-label>
            <dxi-validation-rule
            type="required"
            message="Transaction Type is required">
          </dxi-validation-rule>
          </dxi-item>

            <dxi-item dataField="LoanNumbers" width="'30%'">
              <dxo-label text="Loan Number"> </dxo-label>
            </dxi-item>
            <dxi-item
              dataField="dateNeeded"
              editorType="dxDateBox"
              [editorOptions]="{ width: '30%' }"
            >
            <dxo-label text="Date Needed"> </dxo-label>
            <dxi-validation-rule
            type="required"
            message="Date Needed is required">
          </dxi-validation-rule>
          </dxi-item>
        
          <dxi-item
            dataField="expectedPayoffDate"
            editorType="dxDateBox"
            [editorOptions]="{ width: '40%'}"
          >
          <dxo-label text="Expected Payoff Date"> </dxo-label>
          <dxi-validation-rule
            type="required"
            message="Expected Payoff Date is required">
          </dxi-validation-rule>
        </dxi-item>
          
          <dxi-item
          dataField="IsRushJob"
          editorType="dxCheckBox"
          [editorOptions]="{ value: isrushjob }"
          >
            <dxo-label text="Is Rush Job?"> </dxo-label>
          
          </dxi-item>
      <!--</dxi-item>
      <dxi-item itemType="group" cssClass="form-group" [colCount]="2">
        <dxi-item
          editorType="dxTextBox" 
          text ="yadya">
        </dxi-item>  
      </dxi-item>
      <dxi-item itemType="group" cssClass="form-group" [colCount]="2">-->
          <dxi-item
              dataField= "triggertypes"
              editorType="dxSelectBox"   
              [editorOptions]="{ items: triggertypes, valueExpr: 'id', displayExpr: 'name', width: '70%', required: 'true' }"
              
            >
            <dxo-label text="Trigger"> </dxo-label>
            <dxi-validation-rule
            type="required"
            message="Trigger is required">
            </dxi-validation-rule>
          </dxi-item>
          </dxi-item>
          <dxi-item itemType="group" cssClass="form-group" [colCount]="2">
            <dxi-item
            dataField="payoffpaydowndata.comments"
            [colSpan]="2"
            editorType="dxTextArea"
            [editorOptions]="{ height: 140 }"
          >
          <dxo-label text="Additional Fees & Comments"> </dxo-label>
        </dxi-item>
          <dxi-item
            dataField="File Upload"
            [colSpan]="2"
            editorType="dxFileUploader"
            height="600"
            [editorOptions]="{ uploadUrl: uploadUrl, accept: '*.*', uploadMode: 'useButtons' }"
            
          ></dxi-item>
          <dxi-item
          dataField="isrushjob"
          editorType="dxCheckBox"
          [editorOptions]="{ value: isrushjob }"
          >
            <dxo-label text="Send me a copy of my responses"> </dxo-label>
          </dxi-item>
      
          <dxi-item
          cssClass="user-button"
          horizontalAlignment="right"
          editorType="dxButton"
          [editorOptions]="{  text: 'Submit', useSubmitBehavior: true}"
          ></dxi-item>
        </dxi-item>

        
        <!--<dxi-item itemType="group" cssClass="form-group" [colCount]="1">
          <dx-button
          id="button"
          text="Update profile"
          type="success"
          (onClick)="submitRequest()"
        >
        </dx-button>
        </dxi-item>-->
        
      <!-- <dxi-item itemType="group" cssClass="form-group" [colCount]="2">
          <dxi-item itemType="group">
            <dxi-item dataField="Address"></dxi-item>
            <dxi-item dataField="City"></dxi-item>
            <dxi-item
              dataField="Position"
              editorType="dxSelectBox"
              [editorOptions]="{ items: positions, value: '' }"
            ></dxi-item>
          </dxi-item>
          <dxi-item itemType="group">
            <dxi-item
              dataField="State"
              editorType="dxSelectBox"
              [editorOptions]="{ items: states }"
            ></dxi-item>
            <dxi-item dataField="ZipCode"></dxi-item>
            <dxi-item
              dataField="Mobile"
              [label]="{ text: 'Phone' }"
              [editorOptions]="{ mask: '+1 (000) 000-0000' }"
            ></dxi-item>
          </dxi-item>
          <dxi-item
            dataField="Notes"
            [colSpan]="2"
            editorType="dxTextArea"
            [editorOptions]="{ height: 140 }"
          ></dxi-item>
        </dxi-item>-->
      </dx-form>
    </form>
</dx-scroll-view>
<dx-load-panel
  container=".cards-wide"
  [position]="{ of: '.content' }"
  [visible]="isLoading"
  [showPane]="true"
  
></dx-load-panel>

