<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">

    <div class="dx-widget">
      <div class="logo">
        <h2><span style="font-weight: 700;">CREDIT</span>HUB</h2>
      </div>
      <div class="login-user">
        <h3>Welcome</h3>
      </div>

      
       
        <div class="input-btn mt-4">
          <dx-button class="btn btn-primary" style="width: 160px;" (onClick)="onSubmit()">Log in</dx-button>
        </div>
      

      
      <div class="legal-notice">
        <a href="https://mountstreet.com/privacy-policy" target="_blank">Privacy Policy</a>
        <a href="https://mountstreet.com/terms-and-conditions" target="_blank">Terms and Conditions</a>
        <a href="https://mountstreet.com/cookie-policy" target="_blank">Cookie Policy</a>
        <a href="https://mountstreet.com/acceptable-use-policy/" target="_blank">Acceptable Use Policy</a>
      </div>
    </div>
  </div>