
<dx-scroll-view>
<form (submit)="onSubmit($event)">
<dx-form id="form" class="cards-wide"
[formData]="voucherData"
[showValidationSummary]="true"

validationGroup="formGroup"
>
<dxi-item itemType="group" cssClass="form-group form-group-content" [colCount]="1">
  <!--<dxi-item
  dataField="dueDate"
  editorType="dxDateBox"
  [editorOptions]="{ width: '40%'}"
    >
    <dxo-label text="Due Date"> </dxo-label>
    <dxi-validation-rule
      type="required"
      message="Due Date is required">
    </dxi-validation-rule>
</dxi-item>-->
    <dxi-item
        labelMode="static"
        dataField="voucherType"
        editorType="dxSelectBox"

        [editorOptions]="{ items: taskVoucherTypes, valueExpr: 'name', displayExpr: 'name',
               width: '70%', required: 'true' , searchMode: 'contains', searchEnabled: true }"
      >
      <dxo-label text="Voucher Type"> </dxo-label>
      <dxi-validation-rule
      type="required"
      message="Voucher Type is required">
    </dxi-validation-rule>
    </dxi-item>
    <dxi-item
    dataField="feesPaidToMS"
    editorType="dxCheckBox"
    [editorOptions]="{ }"
    >
      <dxo-label text="If voucher includes fees paid to MS, you must check this box!"> </dxo-label>
     
    </dxi-item>
    <dxi-item
        labelMode="static"
        dataField="bank"
        editorType="dxSelectBox"

        [editorOptions]="{ items: bankData, valueExpr: 'id', displayExpr: 'name',
               width: '70%', required: 'true' , searchMode: 'contains', searchEnabled: true}"
      >
      <dxo-label text="Refer to the bank name in the debit info section on the voucher being submitted"> </dxo-label>
      <dxi-validation-rule
      type="required"
      message="Bank is required">
    </dxi-validation-rule>
    </dxi-item>
    <dxi-item
        labelMode="static"
        dataField="loanNumber"
        editorType="dxSelectBox"

        [editorOptions]="{ items: loanData, valueExpr: 'id', displayExpr: 'name',
               width: '70%', required: 'true' , searchMode: 'contains', searchEnabled: true}"
      >
      <dxo-label text="Loan Number"> </dxo-label>
      <dxi-validation-rule
      type="required"
      message="Loan Number is required">
    </dxi-validation-rule>
    </dxi-item>
    <dxi-item
        labelMode="static"
        dataField="billableToClient"
        editorType="dxSelectBox"

        [editorOptions]="{ items: billableToClientData, valueExpr: 'name', displayExpr: 'name',
               width: '70%', required: 'true' , searchMode: 'contains', searchEnabled: true}"
      >
      <dxo-label text="Billable to Client"> </dxo-label>
      <dxi-validation-rule
      type="required"
      message="Please select the Billable to Client option">
    </dxi-validation-rule>
    </dxi-item>
    <dxi-item  
        class = '.dx-numberbox'
        labelMode="static"
        dataField="voucherAmount"
        editorType="dxNumberBox"

        [editorOptions]="{
               width: '70%', required: 'true'}"
      >
      <dxo-label text="Voucher Amount"> </dxo-label>
     
      <dxi-validation-rule
      type="range" min="0.01"
      message="Voucher Amount must be greater than zero"/>
    </dxi-item>
    <dxi-item
    cssClass="user-button"
    horizontalAlignment="right"
    editorType="dxButton"
    [editorOptions]="{  text: 'Submit', useSubmitBehavior: true}"
    ></dxi-item>
  </dxi-item>
</dx-form>
<dx-load-panel
  container=".cards-wide"
  [position]="{ of: '.content' }"
  [visible]="isLoading"
  [showPane]="true"
  
></dx-load-panel>
</form>