import { ApiService } from './api.service';
import { Observable } from 'rxjs/internal/Observable';
import { Injectable } from '@angular/core';
import { AppConfig } from '../../app.config';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
  })

  

    export class ProfileDataService
    {
            constructor(private api: ApiService) { }

                //private baseUrlx: any = appConfig.settings.apiServer.baseUrl;
                private controller = 'User';
                private userPreferencesController = 'UserPreferences'
                private getMethod = 'get';
                baseUrl: string = environment.profileServiceEndPoint;
        

                public getAllUsers(includeInactive: boolean) : Observable<any>
                {
                    return this.api.get(`${this.baseUrl}/${this.controller}/getall?includeInactive=${includeInactive}`);
                }

                public getUserPreferences(userId: string) : Observable<any>
                {
                  return this.api.get(`${this.baseUrl}/${this.userPreferencesController}?userid=${userId}`);
                }

                public getTeams() : Observable<any>
                {
                    return this.api.get(`${this.baseUrl}/Group/teams`);
                }

                public getApproverGroups() : Observable<any>
                {
                    return this.api.get(`${this.baseUrl}/Group/approverGroups`);
                }

                
        
    }

