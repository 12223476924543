export const SECURITY_EVENTS = {
  LOGGED_IN: "LOGGED_IN",
  LOGGED_OUT: "LOGGED_OUT",
  NO_TOKEN: "NO_TOKEN",
  INVALID_TOKEN: "INVALID_TOKEN",
} as const;

export const APPLICATION_EVENTS = {
    A: "A",
    B: "B",
  } as const;
  
export type TRACKED_EVENT_TYPES = typeof SECURITY_EVENTS;// | typeof APPLICATION_EVENTS;