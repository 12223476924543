
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import notify from 'devextreme/ui/notify';
import validationEngine from "devextreme/ui/validation_engine";
import { ScreenService } from '../../../shared/services';
import { TaskDataService } from '../../../shared/services/task-data.service';



@Component({
  selector: 'app-payoff-paydown',
  templateUrl: './payoff-paydown.component.html',
  styleUrls: ['./payoff-paydown.component.css']

})
export class PayoffPaydownComponent implements OnInit {

  @Output() onSubmitTaskEventEmitter: EventEmitter<any> = new EventEmitter();

  payoffpaydowndata: any
  requesttypes: any[]
  transactiontypes: any[]
  triggertypes: any[]
  isrushjob: boolean
  uploadUrl: string ="#"
  expectedPayoffDate: Date = new Date()
  dateNeeded: Date = new Date()
  isLoading: boolean;

 
 

  constructor(protected screen: ScreenService, private taskDataService: TaskDataService) 
  { 
    this.payoffpaydowndata = {};
    this.requesttypes= [ {'id': 'a', 'name': 'Full Payoff'}, {'id': 'b', 'name': 'Paydown'}, {'id': 'b', 'name': 'Note Sale'}, {'id': 'b', 'name': 'Unit Sale'}]
    this.transactiontypes = [ {'id': 'a', 'name': 'Funds Going to MSUS'}, {'id': 'b', 'name': 'Non-Cash Transaction'}]
    this.triggertypes = [ {'id': 'a', 'name': 'Borrower'}, {'id': 'b', 'name': 'Client'}], {'id': 'b', 'name': 'Internal'};
    this.isrushjob = true;
  }

  



  onSubmit(e: Event)
  {
    console.log(this.payoffpaydowndata);
    let validationResult = validationEngine.validateGroup("formGroup");

    if (!validationResult.isValid){
        notify("Please enter the required values!");
    }
    else
    { 
      let x = this.taskDataService;
      this.onSubmitTaskEventEmitter.emit(e);
      notify("Submit clicked");
       
    }

    
  }

  

  ngOnInit() {
  }

}


export class PayoffPaydownModule { }
