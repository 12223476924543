
<h1 mat-dialog-title>Logout</h1>

<div mat-dialog-content>
    Are you sure you want to log out?
</div>

<br>

<div class="float-right">
    <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">Cancel</button>
        <!--class="btn btn-danger"-->
        <dx-button mat-button [mat-dialog-close]="data.logoutMonitorValue" cdkFocusInitial>Log out</dx-button>
    </div>
</div>