export interface ISystemNotification {
  messageType: MessageType;
  message: string;
}

export enum MessageType {
  Success = 0,
  Information = 1,
  Warning = 2,
  Error = 3,
}
