import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../framework/services/auth.service';
import { LoggingService } from '../../../../framework/logging/logging.service';
import { ProfileDataService } from '../../../services/profile-data.service';


@Component({
    selector: 'signin-callback',
    template: `
                    <div class="container-fluid">
                        <h2 class="logon-status-info">Logging In...</h2>
                    </div>

            `
})

export class SignInRedirectCallbackComponent implements OnInit {
    
    constructor(private _authService:AuthService,
        private _router: Router, private loggingService: LoggingService, private profileDataService: ProfileDataService) { }

        ngOnInit() {
            this._authService.completeLogin().then(user => {
                if(user.access_token !== '' && user.profile['name'] !==''){
                    localStorage.setItem('loggedInUser', user.profile['name']);
                    localStorage.setItem('tasksApiToken', 'Bearer ' + user.access_token);
  
                    this.profileDataService.getUserPreferences(user.profile['id']).subscribe((rd: any) =>
                        {
                          localStorage.setItem("userPreferences", rd);
                        }); 
                    this._router.navigate(["tasks"]);
                }
                else
                    this._authService.logout();
            })
         }
 }
 