import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sandbox',
  templateUrl: './sandbox.component.html',
  styleUrls: ['./sandbox.component.css']
})
export class SandboxComponent implements OnInit {

  selectedDealId: string = "417bd000-659e-4e52-838a-ec6e20ce26f9";

  constructor() { }

  

  ngOnInit() {
  }

}
