
<!--<div>
 
    <h4>Task Details
    </h4>
    <dx-button icon="clear" (onClick)="closePanel()"> </dx-button>
 
  <Details>
    <dx-button icon="clear" (onClick)="closePanel()"> </dx-button>
  </Details>
</div>-->

<!--<div class="content-block dx-card responsive-paddings">
  <div class="form-avatar">
      <label class="dxo-label, center">Task Details</label>
    <dx-button icon="clear" (onClick)="closePanel()" class="dx-button, right-float"> </dx-button>
  </div>
</div>-->
<div class="content-block">
  <dx-button icon="clear" (onClick)="closePanel()" class="dx-button, right-float"> </dx-button>
    <!--<label>{{selectedTask}}</label>-->
    <dx-form [formData]="selectedTask" width="100%" 
      [colCount]="1">

      <dxi-item itemType="group" cssClass="form-group" [colCount]="2">
        <dxi-item dataField="name">
          <dxo-label text="Task Name"> </dxo-label>
        </dxi-item>
        <dxi-item
        dataField="alertDate"
        editorType="dxDateBox"
        [editorOptions]="{ }"
      >
        <dxo-label text="Alert Date"> </dxo-label>
        <dxi-validation-rule
        type="required"
        message="Alert Date is required">
      </dxi-validation-rule>
      </dxi-item>
     </dxi-item>
     <dxi-item itemType="group" cssClass="form-group" [colCount]="2">
        <dxi-item
          dataField="dueDate"
          editorType="dxDateBox"
          [editorOptions]="{ }"
        >
          <dxo-label text="Due Date"> </dxo-label>
          <dxi-validation-rule
          type="required"
          message="Due Date is required">
        </dxi-validation-rule>
        </dxi-item>
        <dxi-item
        labelMode="static"
        dataField="status"
        editorType="dxSelectBox"
        [editorOptions]="{ items: taskStatuses, valueExpr: 'id', displayExpr: 'name', width: '70%', required: 'true'  }"
      >
      <dxo-label text="Status"> </dxo-label>
      <dxi-validation-rule
      type="required"
      message="Status is required">
      </dxi-validation-rule>
      </dxi-item>
     </dxi-item>
    </dx-form>

</div>   


