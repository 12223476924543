import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {



constructor() { 
  
}

referenceTypeSelectedValue = "0"
mandateName = "Test Mandate"

public convertTasks(taskArray: any[])
{
  taskArray.map( (el) => {
  // console.log('in saveGenerated. el: ', el);
  let mappedEl: Record<string, any> = {};
  mappedEl['task'] = {};
  mappedEl['task'].referenceId = el.referenceId;
  mappedEl['task'].reference = el.reference;
  mappedEl['task'].description = el.taskDescription;
  mappedEl['task'].referenceType = el.referenceType;
  mappedEl['task'].mandateName = this.mandateName;
  mappedEl['task'].referenceTypeId = this.referenceTypeSelectedValue;
  mappedEl['task'].cycleId = 0;
  mappedEl['task'].source = el.source;
  mappedEl['task'].name = el.taskName;
  mappedEl['task'].taskConfigurationId = el.taskConfigurationId;
  mappedEl['task'].maxSnoozeDays = el.maxSnooze;
  mappedEl['task'].alertDate = el.defaultAlertDate;
  mappedEl['task'].dueDate = el.defaultDueDate;
  mappedEl['task'].taskTags = [{key: 'Reference Type', value: el.referenceType}, {key: 'Reference Name', value: el.reference}, {key: 'Reference Id', value: el.referenceId}, {key: 'Mandate Name', value: this.mandateName}]

  if (el.rescheduleOnExecution === "1") {
    mappedEl['task'].rescheduleOnExecution = true;
  } else if (el.rescheduleOnExecution === "2") {
    mappedEl['task'].rescheduleOnExecution = false;
  }

  let rolesArray = [];
  let usersArray = [];
  if (Object.keys(el.primary).length > 0) {
    if (el.primary !== "00000000-0000-0000-0000-000000000000") {
      rolesArray.push({
        taskRoleTypeId: "e251ba5d-bbd6-4f16-8737-7fd5c2e15dbe",
        roleId: el.primary,
      });
    }
  }

  if (Object.keys(el.secondary).length > 0) {
    if (el.secondary !== "00000000-0000-0000-0000-000000000000") {
      rolesArray.push({
        taskRoleTypeId: "4ea4c595-113d-49a7-a79a-a7a4256b74c0",
        roleId: el.secondary,
      });
    }
  }

  if (Object.keys(el.approverRole).length > 0) {
    if (el.approverRole !== "00000000-0000-0000-0000-000000000000") {
      rolesArray.push({
        taskRoleTypeId: "e7ac5aec-01ef-494f-96c1-cefa480f38ba",
        roleId: el.approverRole,
      });
    }
  }

  if (Object.keys(el.primaryUser).length > 0) {
    if (el.primaryUser !== "1") {
      usersArray.push({
        taskRoleTypeId: "e251ba5d-bbd6-4f16-8737-7fd5c2e15dbe",
        userId: el.primaryUser,
      });
    }
  }

  if (Object.keys(el.secondaryUser).length > 0) {
    if (el.secondaryUser !== "1") {
      usersArray.push({
        taskRoleTypeId: "4ea4c595-113d-49a7-a79a-a7a4256b74c0",
        userId: el.secondaryUser,
      });
    }
  }

  if (Object.keys(el.approverUser).length > 0) {
    if (el.approverUser !== "1") {
      usersArray.push({
        taskRoleTypeId: "e7ac5aec-01ef-494f-96c1-cefa480f38ba",
        userId: el.approverUser,
      });
    }
  }

  mappedEl['taskRoles'] = rolesArray;
  mappedEl['userTaskRoleTypes'] = usersArray;
  return mappedEl;
});

}
}
