export const navigation = [
  
   
  {
    text: 'Tasks',
    icon: 'orderedlist',
    path: '/tasks'
  }
  ,
  {
    text: 'Configuration',
    icon: 'preferences',
    items: [
      {
        text: 'Generate Tasks',
        path: '/configuration/generatetasks'
      }
    ]
  }
      ,
      {
        text: 'Kanban',
        path: '/kanban'
      }
      ,
      {
        text: 'Sandbox',
        path: '/sandbox'
      }
  ];
  

