<dx-scroll-view>
  <dx-form id="form" class="cards-wide"
[formData]="generatedTasksData"
[showValidationSummary]="true"

validationGroup="formGroup"
>
      <dxi-item itemType="group" cssClass="form-group form-group-content" [colCount]="3" labelMode="static">
          <dxi-item
              
              dataField="referenceType"
              editorType="dxSelectBox"
              [editorOptions]="{ items: referenceTypes, valueExpr: 'name', displayExpr: 'name', width: '70%', required: 'true'  }"
            >
            <dxo-label text="Reference Type"> </dxo-label>
            <dxi-validation-rule
            type="required"
            message="Reference Type is required">
          </dxi-validation-rule>
          </dxi-item>
          <!--<dxi-item
              
              dataField="reference"
              editorType="dxSelectBox"
              [editorOptions]="{ items: references, valueExpr: 'name', displayExpr: 'name', width: '70%', required: 'true'
                              ,searchMode: 'contains', searchEnabled: true }"
            >
            <dxo-label [text]="referencesHeaderText"> </dxo-label>
            <dxi-validation-rule
            type="required"
            [message]="referencesValidationMessage">
          </dxi-validation-rule>
          </dxi-item>-->
        
       <dxi-item
              
              dataField="useTemplate"
              editorType="dxSelectBox"
              [editorOptions]="{ items: templateYesNo, valueExpr: 'id', displayExpr: 'name', width: '50%', required: 'true',
                selectedIndex: 1
                }"
            >
            <dxo-label text="Use Template?"> </dxo-label>
            <dxi-validation-rule
            type="custom"
            message="Please select a template"
            [validationCallback]="onValidateTemplate"
            [reevaluate]="true">>
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item
            dataField="taskTemplateId"
            editorType="dxSelectBox"
            
            [editorOptions]="{ items: taskTemplates, valueExpr: 'id', displayExpr: 'name', width: '70%', required: 'false', onValueChanged: onTemplateChanged,
               searchMode: 'contains', searchEnabled: true
             }"
        >
      <dxo-label text="Task Template"> </dxo-label>
    </dxi-item>
        <dxi-item
          dataField="referenceDate"
          editorType="dxDateBox"
          [editorOptions]="{ width: '60%'}"
            >
            <dxo-label text="Reference Date"> </dxo-label>
            <dxi-validation-rule
              type="required"
              message="Reference Date is required">
            </dxi-validation-rule>
        </dxi-item>
         <dxi-item
              
              dataField="dateRollConvention"
              editorType="dxSelectBox"
              [editorOptions]="{ items: rollConventions, valueExpr: 'name',  displayExpr: 'name', width: '50%', required: 'true',
                selectedIndex: 1
                }"
            >
            <dxo-label text="Roll Convention"> </dxo-label>
            <dxi-validation-rule
            type="required"
            message="Roll convention is required]">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item
              
              dataField="frequency"
              editorType="dxSelectBox"
              [editorOptions]="{ items: frequencies, valueExpr: 'text', displayExpr: 'text', width: '70%', required: 'true'  }"
            >
            <dxo-label text="Frequency"> </dxo-label>
            <dxi-validation-rule
            type="required"
            message="Frequency is required">
          </dxi-validation-rule>
        </dxi-item>
        
       
        <dxi-item
          dataField="referenceDateAsFirstDueDate"
          editorType="dxCheckBox"
          [editorOptions]="{ }"
          >
            <dxo-label text="User Reference Date As First Due Date?"> </dxo-label>
          
          </dxi-item>

          <dxi-item
              
              dataField="tenor"
              editorType="dxSelectBox"
              [editorOptions]="{ items: tenors, valueExpr: 'name', displayExpr: 'name', width: '50%', required: 'true',
                selectedIndex: 1
                }"
            >
            <dxo-label text="Tenor"> </dxo-label>
            <dxi-validation-rule
            type="required"
            message="Tenor is required]">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item
              
              dataField="numberOfCycles"
              editorType="dxNumberBox"
              [editorOptions]="{  width: '70%', required: 'true' , format: '#', defaultValue: '' }"
            >
            <dxo-label text="Number Of Cycles"> </dxo-label>
            <dxi-validation-rule
            type= "custom"
            message="Number Of Cycles OR End Date can be specified - Not both!"
            [validationCallback]="onValidateCycles"
            [reevaluate]="true">
          </dxi-validation-rule>
        </dxi-item>
        <dxi-item
        dataField="untilDate"
        editorType="dxDateBox"
        [editorOptions]="{ width: '60%'}"
          >
          <dxo-label text="End  Date"> </dxo-label>
          <!--<dxi-validation-rule
            type="required"
            message="End Date is required">
          </dxi-validation-rule>-->
      </dxi-item>
      
    <dxi-item
      
      dataField="locationArray"
      editorType="dxTagBox"
      [colSpan] = "2"
      [editorOptions]="{ items: businessDayCalendars, valueExpr: 'shortName', displayExpr: 'name', width: '70%', required: 'true' 
                      , searchMode: 'contains', searchEnabled: true, selectAllMode:'allPages', maxDisplayedTags: '3', showMultiTagOnly: false,
                      showSelectionControls: false
       }"
    >
        <dxo-label text="Business Day Calendar"> </dxo-label>
        <dxi-validation-rule
        type="required"
        message="Business Day Calendar is required">
      </dxi-validation-rule>
      
    </dxi-item>
    <dxi-item
              
      dataField="assignedUserName"
      editorType="dxSelectBox"
      [editorOptions]="{ items: allUsers, valueExpr: 'firstName', displayExpr: 'firstName', width: '70%', required: 'false'  
                        , searchMode: 'contains', searchEnabled: true
      }"
    >
          <dxo-label text="Assigned User"> </dxo-label>
      </dxi-item>
   

  <dxi-item
              
      dataField="team"
      editorType="dxSelectBox"
      [editorOptions]="{ items: teams, valueExpr: 'name', displayExpr: 'name', width: '70%', required: 'true'  
                        , searchMode: 'contains', searchEnabled: true}"
    >
          <dxo-label text="Team"> </dxo-label>
          <dxi-validation-rule
          type="required"
          message="Team is required">
        </dxi-validation-rule>
      </dxi-item>
      <dxi-item
              
      dataField="approverGroup"
      editorType="dxSelectBox"
      [editorOptions]="{ items: approverGroups, valueExpr: 'name', displayExpr: 'name', width: '70%', required: 'false'  
                        , searchMode: 'contains', searchEnabled: true}"
    >
          <dxo-label text="Approver Group"> </dxo-label>
      </dxi-item>

  </dxi-item>
     
  </dx-form>
  <dx-button
    text="Generate Tasks"
    (onClick)="generateTasks($event)">
</dx-button>
</dx-scroll-view>
<div>
  <dx-data-grid
    id="gridContainer"
    [dataSource]="generatedTasksData.tasks"
    width="auto"
    keyExpr="id"
    [showBorders]="true"
  >
    <dxo-paging [enabled]="false"></dxo-paging>
        <dxo-editing
          mode="batch"
          [allowUpdating]="allowEditing"
          [allowAdding]="allowAdding"
          [allowDeleting]="allowEditing"
          [selectTextOnEditStart]="allowEditing"
          [startEditAction]="editAction"
        >
        </dxo-editing>

        <dxi-column dataField="Prefix" caption="Title" [width]="70"></dxi-column>
    </dx-data-grid>
</div>
