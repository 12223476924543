
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DashboardContainerComponent } from './shared/components/dashboard/dashboard-container/dashboard-container.component';
import { DashboardHeaderComponent } from './shared/components/dashboard/dashboard-header/dashboard-header.component';
import { CommonModule } from '@angular/common';
import { DxScrollViewModule } from 'devextreme-angular/ui/scroll-view';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ResetPasswordFormComponent, CreateAccountFormComponent, ChangePasswordFormComponent, HeaderComponent, HeaderModule } from './shared/components';
import { AuthGuardService } from './shared/components/framework/services/auth-guard.service';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { KanbanComponent } from './pages/kanban/kanban.component';
import { SandboxComponent } from './pages/sandbox/sandbox.component';
import { PayoffPaydownComponent } from './pages/dataentry/payoff-paydown/payoff-paydown.component';
import { DataGridComponent } from './shared/components/data-grid/data-grid.component';
import { TaskDetailComponent } from './pages/tasks/task-detail/task-detail.component';
import { NewTaskComponent } from './pages/tasks/new-task/new-task.component';
import { DxDataGridModule, DxFormModule, DxDrawerModule, DxSortableModule, DxFileUploaderModule, DxButtonModule, DxDropDownButtonModule, 
  DxTooltipModule, DxValidatorModule, DxMenuModule, DxLoadPanelModule, DxContextMenuModule, DxTabsModule, DxPopupModule, DxTemplateModule, DxSelectBoxModule,
  DxPieChartModule, DxChartModule, DxFileManagerModule, DxCheckBoxModule, DxTreeListModule} from 'devextreme-angular';
import { DxoPositionModule, DxoHeightModule, DxoTooltipModule, DxoPermissionsModule, DxiToolbarItemModule } from 'devextreme-angular/ui/nested';
import { DashboardPieChartsComponent } from './shared/components/dashboard/dashboard-charts/dashboard-pie-charts.component';
import { DashboardBarChartsComponent } from './shared/components/dashboard/dashboard-charts/dashboard-bar-charts/dashboard-bar-charts.component';
import { TestComponent } from './shared/components/test/test.component';
import { DocumentsComponent } from './shared/components/documents/documents.component';
import { VoucherComponent } from './pages/dataentry/voucher/voucher/voucher.component';
import { MaterialModule } from './shared/material-module.module';
import { TopNavComponent } from './shared/components/framework-ui/navigation/header/top-nav/top-nav.component';
import { LogoutDialogComponent } from './shared/components/framework-ui/navigation/logout/logout-dialog/logout-dialog.component';
import { LoginComponent, LoginModule } from './shared/components/framework/login/login.component';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { SignInRedirectCallbackComponent } from './shared/components/framework-ui/navigation/signin-redirect-callback.component';
import { SignOutRedirectCallbackComponent } from './shared/components/framework-ui/navigation/signout-redirect-callback.component';
import { GenerateTasksComponent } from './pages/generate-tasks/generate-tasks.component';









const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'signin-callback', component: SignInRedirectCallbackComponent },
  { path: 'signout-callback', component: SignOutRedirectCallbackComponent },
 
 {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [ AuthGuardService ]
  },
   {
    path: 'configuration/generatetasks',
    component: GenerateTasksComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'sandbox',
    component: SandboxComponent ,
    canActivate: [ AuthGuardService ]
  },
 /* {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuardService ]
  },
 
  {
    path: 'reset-password',
    component: ResetPasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'create-account',
    component: CreateAccountFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: 'change-password/:recoveryCode',
    component: ChangePasswordFormComponent,
    canActivate: [ AuthGuardService ]
  },
  {
    path: '**',
    redirectTo: 'home'
  }*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false }),
    DxDataGridModule,
    DxFormModule,
    DxDrawerModule,
    DxScrollViewModule,
    DxSortableModule,
    DxFileUploaderModule,
    DxButtonModule,
    DxTooltipModule,
    DxValidatorModule,
    DxMenuModule,
    DxDropDownButtonModule,
    DxLoadPanelModule,
    DxoPositionModule,
    DxoHeightModule,
    DxoTooltipModule,
    DxContextMenuModule,
    DxTabsModule,
    DxToolbarModule,
    DxPieChartModule,
    DxChartModule,
    DxFileManagerModule,
    DxoPermissionsModule,
    DxPopupModule,
    DxiToolbarItemModule,
    DxTemplateModule,
    DxSelectBoxModule,
    MaterialModule,
    CommonModule,
    HeaderModule,
    LoginModule,
    DxCheckBoxModule,
    DxTreeListModule
   
   
    

   ],

  providers: [
    AuthGuardService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService
  ],
  exports: [RouterModule],
  declarations: [
    HomeComponent,
    ProfileComponent,
    TasksComponent,
    KanbanComponent,
    SandboxComponent,
    PayoffPaydownComponent,
    DataGridComponent,
    TaskDetailComponent,
    DashboardContainerComponent,
    DashboardHeaderComponent,
    DashboardPieChartsComponent,
    DashboardBarChartsComponent,
    TestComponent,
    DocumentsComponent,
    VoucherComponent,
    TopNavComponent,
    LogoutDialogComponent,
    TopNavComponent,
    NewTaskComponent,
    GenerateTasksComponent
    

   

    
  
  ]
})
export class AppRoutingModule { } 
