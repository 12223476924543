import { IUserProfile } from './user-profile';
import { ISimpleClaim } from './simple-claim';

export interface IAuthContext {
  claims: ISimpleClaim[];
  userProfile: IUserProfile;
}

export class AuthContext implements IAuthContext {
  claims: ISimpleClaim[];
  userProfile: IUserProfile;

  roleTypeName : string  = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';

  get isBorrower() {
    return !!this.claims && !!this.claims.find(c => c.type === this.roleTypeName && c.value.toLowerCase() === 'borrower');
  }

  get isLender() {
    return !!this.claims && !!this.claims.find(c => c.type === this.roleTypeName && c.value.toLowerCase() === 'lender');
  }

  get isAssetManager() {
    return !!this.claims && !!this.claims.find(c => c.type === this.roleTypeName && c.value.toLowerCase() === 'assetmanager');
  }

  get name() {
    return this.claims.some(c => c.type.endsWith('given_name')) ? this.claims.find(c => c.type.endsWith('given_name')).value : "Log out";
  }

  get hasMultiplePermittedMandateIds(){
    return this.userProfile.permittedMandateIds.length > 1
  }
}


