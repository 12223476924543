
import  notify  from 'devextreme/ui/notify';
import { TaskDataService } from './../../shared/services/task-data.service';
import { Component,  ViewChild, OnInit, ChangeDetectorRef  } from '@angular/core';
import  CustomStore  from 'devextreme/data/custom_store'
import { DxDataGridTypes } from 'devextreme-angular/ui/data-grid';
import { DxListModule, DxRadioGroupModule, DxToolbarModule } from 'devextreme-angular';
import { DxDrawerModule, DxDrawerComponent, DxDrawerTypes } from 'devextreme-angular/ui/drawer';
import { DxoPagerComponent } from 'devextreme-angular/ui/nested';
import { ProfileDataService } from '../../shared/services/profile-data.service';
import { DxContextMenuTypes } from 'devextreme-angular/ui/context-menu';
import { IGridColumn, ITaskResponse, ITaskTag, TasksNavigation } from '../../modules/tasks/model/types';
import { Router } from '@angular/router';
import { assignTaskToMe } from '../../types/task-resources';
import { DataGridComponent } from '../../shared/components/data-grid/data-grid.component';
import { ITasksNavigation } from '../../modules/tasks/model/types';
import { DatePanelItem, datePanelItems } from '../../types/resource';
import { DxTreeListComponent, DxTreeListTypes } from 'devextreme-angular/ui/tree-list';
import { dxTreeListRowObject } from 'devextreme/ui/tree_list';
import { DxTreeViewTypes } from 'devextreme-angular/ui/tree-view';
import { LoggingService } from '../../framework/logging/logging.service';
import { SeverityLevel } from '../../framework/logging/severity-level.model';





@Component({
  templateUrl: 'tasks.component.html',
  selector: 'task-detail',
  styleUrls: ['./tasks.component.css']
})



export class TasksComponent implements OnInit {
 @ViewChild('dxTreeList', { static: false }) treeList: DxTreeListComponent;

  dataSource: any;
  rowData: ITaskResponse[] = [];
  userData: any;
  priority: any[] = [];
  isLoaded: boolean = false;
  selectedTask: any;
  taskColumnData: IGridColumn[] = [];
  subTaskColumnData: IGridColumn[];
  onEditorPreparing: Function = () => null;
  refreshMode: string = 'full';
  dataPageNumber: number = 1;
  gridPageIndex: number = 0;
  contextMenuItems: Record<any, unknown>[];
  today: Date = new Date();
  taskNavigationFilterParameters: any; //ITasksNavigation;
  isLoading: boolean = false;
  addNewText: string;
  storageKey: string;
 


 

  // drawer settings
  openedStateMode: DxDrawerTypes.OpenedStateMode = 'shrink';
  selectedPosition: DxDrawerTypes.PanelLocation = 'right';
  selectedRevealMode: DxDrawerTypes.RevealMode = 'expand';
  isDrawerOpen!: boolean

  //grid settings
  displayMode: DxoPagerComponent["displayMode"] = 'full';
  allowedPageSizes: DxoPagerComponent["allowedPageSizes"] = 'auto';
  showPageSizeSelector = true;
  showInfo = true;
  showNavButtons = true;

  taskTemplates : any[];

    gridFilterOptions: DatePanelItem[] = datePanelItems;
  

  // context menu keys
  assign: string = "assign";
  reschdule: string = "reschedule";
  manageLogs: string = "manageLogs"

  //popup
  addNewTaskVisible: boolean = false;
  addNewTemplateType: string;

    
  userPreferenceActions: Array<{id: Number, icon: String, class: String}> = [
    { id: 1, icon: "save", class: "dx-button" },
    { id: 2, icon: "clear", class: "dx-button"}
    
    ];
    dropDownOptions = {
      height: 100,
      width: 80
    }

    addNewDropDownOptions = {
      height: 150,
      width: 250
    }

    filterDropDownOptions = {
      height: 150,
      width: 200,
      
    }
 
    refreshTreeView() {
      this.treeList.instance.refresh();
    }

    clearAllFilters() {
      this.treeList.instance.clearFilter();
    }

    saveTreeViewPreferences()
    {
      let x = localStorage.getItem("userPreferences");
    }
  
    userPreferencesSelectionChanged(e: any)
    {
      if(e.itemData.id ==1)
      {
        this.saveTreeViewPreferences();
      }
      else
      {
        this.clearTreeViewPreferences();
      }
    }
  
    clearTreeViewPreferences() {
      if (localStorage.getItem(this.storageKey) !== null) {
        localStorage.removeItem(this.storageKey);
        this.treeList.instance.state(null);
      }
    }


  onCellPrepared(e: DxTreeListTypes.CellPreparedEvent)
  {
    if (e.rowType === "data") {
      if (e.column.dataField === "dueDate")
      {
        let dt = new Date(e.data.dueDate);
        
        if(dt.getTime() < this.today.getTime())
          e.cellElement.style.cssText = "background-color: red";
      }
      else  if (e.column.dataField === "alertDate")
      {
        let dt = new Date(e.data.alertDate);
        
        if(dt.getTime() < this.today.getTime())
          e.cellElement.style.cssText = "background-color: orange";
      }
      
    }
  }

  onAddNewTask(e: any)
  {
    this.addNewTaskVisible = false;
  }
 

 onAddNew(e: any){
      this.addNewTemplateType = e.itemData.name;
      this.ref.detectChanges();
      this.addNewTaskVisible = !this.addNewTaskVisible;
 }

 onFilter(e: any)
 {
    this.taskNavigationFilterParameters = new TasksNavigation()

    this.taskNavigationFilterParameters.startDate = e.itemData.startDate;
    this.taskNavigationFilterParameters.endDate = e.itemData.endDate;

    this.dataPageNumber = 1;
    this.LoadTaskData(true);
 }

 
  

  onContextMenuPreparing(e: DxTreeListTypes.ContextMenuPreparingEvent)
  {
      if(e.row.rowType == 'data')
      {
        this.getContextMenuItems(e);
      }
  }
 
 

  getContextMenuItems(e: DxTreeListTypes.ContextMenuPreparingEvent)
  {
      e.items = [
        { key: this.assign, text: 'Assign To Me',  icon: 'user', onItemClick: () => { this.isLoading = true; assignTaskToMe(e.row.data.id, this.taskDataService).subscribe(
                                                                                                          (response: any) => {
                                                                                                            if (response) {
                                                                                                                this.LoadTaskData(true);
                                                                                                            }
                                                                                                            
                                                                                                        },
                                                                                                        (error) => {
                                                                                                            this.loggingService.logError(error, SeverityLevel.Error);
                                                                                                        },
                                                                                                      )}},
        { key: this.reschdule, text: 'Reschedule Task', icon: 'clock', onItemClick: function() {notify(e.columnIndex);}},
        { key: this.manageLogs, text: 'Manage Logs', icon: 'menu', onItemClick: function() {notify(e.columnIndex);}}
    
        /*items:
            [
              { key: 'key3', text: 'Sub Item 1'},
              { key: 'key4', text: 'Sub Item 2'}
            ]
        */
         
      ];
  }

  


  

  private isExpanded: boolean;

  onRowClick(e : DxTreeListTypes.RowClickEvent)
  {
   
    
    if(e.rowType == 'group' || e.isExpanded != this.isExpanded || e.isExpanded)
    {
      this.isExpanded = e.isExpanded;
      this.isDrawerOpen = false;
    }
    else
    {
      this.isDrawerOpen = true;
      this.selectedTask = e.data;
      notify(`row clicked ${e.data['taskId']}`);
    }

  }

  onDetailRowClick(e : DxTreeListTypes.RowClickEvent)
  {
   
      this.isDrawerOpen = true;
      this.selectedTask = e.data;
      notify(`row clicked ${e.data['taskId']}`);

  }

  loadNextData(e: any)
  {
    this.dataPageNumber++;
    this.LoadTaskData(true);
  }

  loadPreviousData(e: any) 
  {
    if(this.dataPageNumber != 1)
    {
      this.dataPageNumber--;
      this.LoadTaskData(true);
    }
    else
    {
      notify('You have reached the beginning of the dataset');
    }

  }

  closePanel()
  {
      this.isDrawerOpen = false;
  }



 

  

  constructor(private taskDataService: TaskDataService, profileDataService: ProfileDataService,
                      router: Router, private ref: ChangeDetectorRef, private loggingService: LoggingService){

    if(router.getCurrentNavigation()?.extras.state != null)
        this.taskNavigationFilterParameters = router.getCurrentNavigation()?.extras.state;

    
    let userId = localStorage.getItem("userId");

  
    

    taskDataService.getTaskTemplates(false).subscribe((rd: any) =>
    { 
      this.taskTemplates = rd[1];
    });

    profileDataService.getAllUsers(false).subscribe((rd: any) =>
      { 
        this.userData  = rd[1];
      });
  

    this.LoadTaskData(false);
 
  }

  enrichRowData(rowData: ITaskResponse[])
  {
     rowData.forEach( row => { 
      if(row.taskTags.length > 0)
      {
        row.referenceName = this.getTaskTagValue("Reference Name", row.taskTags);
        row.mandate = this.getTaskTagValue("Mandate Name", row.taskTags);
        row.referenceType = this.getTaskTagValue("Reference Type", row.taskTags);
      }

    })
  }

  getTaskTagValue(key: string, tags: ITaskTag[])
  {
    return tags.find( x => x.key == key)?.value
  }

 
  private LoadTaskData(isPaging: boolean)
  {
    //return;
    if(!isPaging)
    {
      this.gridPageIndex = 0;
    }
    this.isLoading = true;

    if(this.taskNavigationFilterParameters == null)
    {
        this.taskDataService.getAll(this.dataPageNumber, 50).subscribe((data) => {
          this.rowData = data[1];//.data.map((t: any) => t.task);
          this.enrichRowData(this.rowData);
          this.dataSource = new CustomStore({ 
            key: "id", 
            load: () => this.rowData
          })
          this.isLoading = false;
        },
        (error) => { 
          this.loggingService.logError(error, SeverityLevel.Error);
         this.isLoading = false;});
    }
    else if(this.taskNavigationFilterParameters.taskStatus != null){

      this.taskDataService.getAllForStatusInDateRange(this.dataPageNumber, 50, this.taskNavigationFilterParameters.startDate, this.taskNavigationFilterParameters.endDate,
        this.taskNavigationFilterParameters.taskStatus)
      .subscribe((data) => {
        this.rowData = data[1];//.data.map((t: any) => t.task);
        this.enrichRowData(this.rowData);
        this.dataSource = new CustomStore({ 
          key: "id", 
          load: () => this.rowData
        });
        this.isLoading = false;
      }
      ,
      (error) => { 
        this.loggingService.logError(error, SeverityLevel.Error);
         this.isLoading = false;});

    }
    else{
      this.taskDataService.getAllInDateRange(this.dataPageNumber, 50, this.taskNavigationFilterParameters.startDate, this.taskNavigationFilterParameters.endDate)
      .subscribe((data) => {
        this.rowData = data[1];//.data.map((t: any) => t.task);
        this.enrichRowData(this.rowData);
        this.dataSource = new CustomStore({ 
          key: "id", 
          load: () => this.rowData
        });
        this.isLoading = false;
      },
      (error) => { 
        this.loggingService.logError(error, SeverityLevel.Error);
       this.isLoading = false;});
      
    }

  }
 
  setTaskGridColumnData() {
    this.taskColumnData = [
      
      //{ field: '', columnType: 'buttons', dataType: '', title:'yada', icons: ['copy']},
      { field: 'taskId', dataType: 'string', title: 'Task Id' },
      { field: 'name', dataType: 'string', title: 'Task Name' },
      { field: 'dueDate', dataType: 'date', title: 'Due Date' },
      { field: 'alertDate', dataType: 'date', title: 'Alert Date' },
      { field: 'status', dataType: 'string', title: 'Status' },
      { field: 'mandate', dataType: 'string', title: 'Mandate' },
      { field: 'referenceName', dataType: 'string', title: 'Reference Name' },
      { field: 'description', dataType: 'string', title: 'Description' },
      { field: 'referenceType', dataType: 'string', title: 'Reference Type' },
      { field: 'riskAllocation', dataType: 'string', title: 'Risk Allocation' },
      { field: 'taskType', dataType: 'string', title: 'Task Type' },
      { field: 'lastComment', dataType: 'string', title: 'Last Comment' },
      { field: 'assignedUserName', dataType: 'string', title: 'Assigned To' },
    
    ];

    this.subTaskColumnData = this.taskColumnData;
  }

  ngOnInit(): void {
    this.isDrawerOpen = false;
    this.addNewText ="add new task";
    this.setTaskGridColumnData();
    this.storageKey = "taskListStorageKey";


    this.tasks = [ {id: "00000000-0000-0000-0000-000000000001" , parentId: null , taskId: "p1", description: "aaa"}, { id: "00000000-0000-0000-0000-000000000002", parentId: "00000000-0000-0000-0000-000000000001", taskId: "b"}, { id: "00000000-0000-0000-0000-000000000003", parentId: "00000000-0000-0000-0000-000000000001", taskId: "c"},
      {id: "00000000-0000-0000-0000-000000000004", parentId: null , dueDate: "20240112", taskId: "p2", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000005", parentId: null , taskId: "p3", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000006", parentId: null , taskId: "p4", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000007", parentId: null , taskId: "p5", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000008", parentId: null , taskId: "p6", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000009", parentId: null , taskId: "p7", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000010", parentId: null , taskId: "p8", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000011", parentId: null , taskId: "p9", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000012", parentId: null , taskId: "p10", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000013", parentId: null , taskId: "p11", description: "bbb"},
      {id: "00000000-0000-0000-0000-000000000014", parentId: null , taskId: "p12", description: "bbb"},
    ]
    
  }
  tasks: any[]

 

}







